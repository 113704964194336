// function dataURLtoBlob (dataurl) {
//   var arr = dataurl.split(',')
//   var mime = arr[0].match(/:(.*?);/)[1]
//   var bstr = atob(arr[1])
//   var n = bstr.length
//   var u8arr = new Uint8Array(n)
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n)
//   }
//   return new Blob([u8arr], { type: mime })
// }

function blobToFile (blob, fileName) {
  blob.lastModifiedDate = new Date()
  blob.name = fileName
  return blob
}

// function dataURLtoFile (dataurl, filename) {
//   var arr = dataurl.split(',')
//   var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n)
//   }
//   var blob = dataURLtoBlob(dataurl)
//   return blobToFile(blob, filename)
// }

// guid 和dbid相互映射
function guIdMapping (guidArrayObj) {
  var guidObj = {}
  var dbIdObj = {}
  for (const key in guidArrayObj) {
    // debugger
    const dbId = guidArrayObj[key][0].dbId
    const name = guidArrayObj[key][0].name
    guidObj[key] = { name, dbId }
    dbIdObj[dbId] = { name, guid: key }
  }
  return { guidObj, dbIdObj }
}

// 树生成, 递归调用
const createTree = (list, parentId = '') => {
  const parentObj = {}
  list.forEach(o => {
    parentObj[o.menuId] = o
  })
  if (!parentId) {
    return list
      .filter(o => !parentObj[o.ancestor] || o.ancestor === o.menuId)
      .map(o => {
        return Object.assign(o, {
          children: createTree(list, o.menuId)
        })
      })
  } else {
    return list
      .filter(o => o.ancestor === parentId && o.ancestor !== o.menuId)
      .map(o => {
        return Object.assign(o, {
          children: createTree(list, o.menuId)
        })
      })
  }
}

// 复制
const copyLink = (val, fn) => {
  const input = document.createElement('input')
  input.setAttribute('readonly', 'readonly')
  // input.setAttribute('value', window.location.origin + window.location.pathname + '#/' + `main?projectId=${this.$route.query.projectId}`)
  input.setAttribute('value', val)
  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, 9999)
  if (document.execCommand('copy')) {
    document.execCommand('copy')
    fn()
  }
  document.body.removeChild(input)
}

function getVertexPosition (el) {
  let currentTarget = el
  let top = 0
  let left = 0
  while (currentTarget !== null) {
    top += currentTarget.offsetTop
    left += currentTarget.offsetLeft
    currentTarget = currentTarget.offsetParent
  }
  return { top, left }
}

function titleCase (str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function extractColorByName (name) {
  if (name && name.length > 0) {
    var temp = []
    temp.push('#')
    for (let index = 0; index < name.length; index++) {
      temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16))
    }
    return temp.slice(0, 5).join('').slice(0, 4)
  }
  return ''
}

const getMobileSystem = () => {
  if (!navigator) return 'web'
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    return 'ios'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return 'android'
  } else {
    return 'web'
  }
}

function filetoBase64 (file, callback) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (e) {
    callback(e.target.result)
  }
}

// 合并数组对象(arr1为主数组)
function mergeArrayObjects (arr1, arr2, key) {
  const obj = {}
  arr2.map(i => {
    obj[i[key]] = i
  })
  return arr1.map(i => {
    if (obj[i[key]]) {
      return {
        ...i,
        ...obj[i[key]]
      }
    } else {
      return i
    }
  })
}

export {
  // dataURLtoBlob,
  blobToFile,
  // dataURLtoFile,
  guIdMapping,
  createTree,
  copyLink,
  getVertexPosition,
  titleCase,
  extractColorByName,
  getMobileSystem,
  filetoBase64,
  mergeArrayObjects
}
