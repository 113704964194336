import { $get, $put } from '../http'
import config from '@/config'
export default {
  /**
   * 获取企业列表
   * 原接口废弃：/core/org/user/bind/list
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getList: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/org/list', data)
  },

  /**
   * 选择企业
   * 原接口废弃：/core/org/user/choice
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  choice: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/org/user/choice', data)
  },

  /**
   * 获取企业详情
   * 原接口废弃：/core/org/detail
   *
   * @param data
   * @param params
   * @returns {Promise | Promise<unknown>}
   */
  getDetail: (data, params) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/org', data)
  },

  /**
   * 获取工作台数据
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getWorkstationData: (data) => {
    return $get(config.EYEGLE_EYE + '/annotation/workstation', data)
  },

  /**
   * 获取企业角色列表
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getRoleList: (data) => {
    return $get(config.BASE_URL + '/core/org/role/list', data)
  },

  /**
   * 获取企业人员列表
   *
   * TODO 废弃，未使用
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getMemberList: (data) => {
    return $get(config.BASE_URL + '/core/org/user/list', data)
  }
}
