export const USERS = {
  SET_USER_INFO: 'SET_USER_INFO' // 设置用户信息
}

// 通用
export const COMMON = {
  SET_LOCALE: 'SET_LOCALE', // 设置多语言
  SET_BREAKPOINT: 'SET_BREAKPOINT', // 设置当前自适应种类
  SET_IS_MESSAGE_MODAL_SHOW: 'SET_IS_MESSAGE_MODAL_SHOW', // 设置鹰眼动态消息弹出是否显示
  SET_HEADER_HIDDEN: 'SET_HEADER_HIDDEN', // 设置头部是否隐藏
  SET_LOGO_HIDDEN: 'SET_LOGO_HIDDEN' // 设置LOGO是否隐藏
}

// 用户端
export const CLIENT = {
  ENTERPRISE: {
    SET_SELECTED: 'SET_SELECTED', // 设置选中的企业ID
    SET_INFO: 'SET_INFO', // 设置当前企业信息
    SET_LIST: 'SET_LIST' // 设置当前企业列表
  }
}
