import { $post, $get } from '../http'
import config from '@/config'
export default {
  /**
   * 邀请项目人员
   *
   * TODO 废弃
   * @deprecated
   * @param orgId
   * @param projectId
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  inviteProjectMember: ({ orgId, projectId } = {}, data = {}) => {
    return $post(config.BASE_URL + '/core/org/project/invite' + `?orgId=${orgId}&projectId=${projectId}`, data)
  },

  /**
   * 邀请企业人员
   *
   * TODO 废弃
   * @deprecated
   * @param orgId
   * @param projectId
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  inviteOrgMember: ({ orgId, projectId } = {}, data = {}) => {
    return $post(config.BASE_URL + '/core/org/invite' + `?orgId=${orgId}&projectId=${projectId}`, data)
  },

  /**
   * 邀请注册
   * 原接口废弃：/core/user/inviteRegister
   *
   * @param data
   * @param isPhone
   * @returns {Promise | Promise<unknown>}
   */
  inviteRegister: (data, isPhone) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/user/inviteRegister', data)
  },

  /**
   * 邀请链接详情
   * 原接口废弃：/core/common/invite
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  checkInviteId: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/invite/info', data)
  }
}
