<template>
  <ArcoModal
    :title="$t('accountProfiles.bindEmail')"
    title-align="start"
    v-model:visible="visible"
    :on-before-ok="submit"
    @before-close="close"
    :ok-text="$t('accountProfiles.confirmBind')"
    :cancel-text="$t('accountProfiles.cancel')">
    <ArcoForm
      :model="form"
      ref="formRef"
      label-align="left"
      :rules="rules">
      <ArcoFormItem
        hide-asterisk
        field="email"
        :label="$t('accountProfiles.emailAddress')"
        validate-trigger="input"
        :validate-status="emailValidateStatus"
        :help="emailValidateTips">
        <ArcoInput
          v-model="form.email"
          :show-word-limit="true"
          :placeholder="$t('accountProfiles.emailInputTips')"
          allow-clear/>
      </ArcoFormItem>
      <ArcoFormItem
        hide-asterisk
        field="verificationCode"
        :label="$t('accountProfiles.verifyCode')">
        <ArcoRow style="width: 100%;" :gutter="8">
          <ArcoCol flex="auto">
            <ArcoInput
              style="flex: 1;"
              v-model="form.verificationCode"
              :placeholder="$t('accountProfiles.verifyCode')" />
          </ArcoCol>
          <ArcoCol flex="none">
            <ArcoButton
              ghost
              type="primary"
              style="width: 100%;"
              @click="sendCode"
              v-if="canGetCode">
              {{ $t('accountProfiles.sendVerifyCode') }}
            </ArcoButton>
            <ArcoButton
              v-else
              ghost
              type="primary"
              style="width: 100%;">
              {{timeLeft}} {{$t('forgetPassword.second')}}
            </ArcoButton>
          </ArcoCol>
        </ArcoRow>
      </ArcoFormItem>
    </ArcoForm>
  </ArcoModal>
</template>

<script setup>
import { reactive, ref, defineExpose, defineEmits } from 'vue'
import {
  Modal as ArcoModal,
  Form as ArcoForm,
  FormItem as ArcoFormItem,
  Input as ArcoInput,
  Row as ArcoRow,
  Col as ArcoCol,
  Button as ArcoButton,
  Message
} from '@arco-design/web-vue'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'

const { t } = useI18n()
const formRef = ref(null)

const emit = defineEmits([
  'success'
])

const emailValidate = async (value, callback) => {
  if (value === '' || !value) {
    // return Promise.reject(new Error(t('accountProfiles.bindEmailTips')))
    callback(t('accountProfiles.bindEmailTips'))
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) { // 是否满足「邮箱」格式
    // return Promise.reject(new Error(t('accountProfiles.emailErrorTips')))
    callback(t('accountProfiles.emailErrorTips'))
  } else {
    return Promise.resolve()
  }
}

const codeValidate = async (value, callback) => {
  if (value === '' || !value) {
    callback(t('forgetPassword.verificationTips'))
    // return Promise.reject(new Error(t('forgetPassword.verificationTips')))
  } else {
    return Promise.resolve()
  }
}
const canGetCode = ref(true)
const timeLeft = ref(0)
const rules = ref({
  email: [{ validator: emailValidate, trigger: 'blur' }],
  verificationCode: [{ validator: codeValidate, trigger: 'blur' }]
})
const visible = ref(false)
const form = reactive({
  email: '',
  verificationCode: ''
})

const show = () => {
  visible.value = true
}

// 邮箱校验状态，用于校验邮箱是否已使用
const emailValidateStatus = ref()
// 邮箱校验失败提示语
const emailValidateTips = ref()

let intervalId

/**
 * 开启计时器
 */
const startTimer = () => {
  if (intervalId) {
    clearInterval(intervalId)
    intervalId = undefined
  }
  canGetCode.value = false
  timeLeft.value = 60
  intervalId = setInterval(() => {
    timeLeft.value -= 1
    if (timeLeft.value <= 0) {
      canGetCode.value = true
      clearInterval(intervalId)
      intervalId = undefined
    }
  }, 1000)
}

/**
 * 获取绑定邮箱验证码
 * @returns {Promise<void>}
 */
const sendCode = async () => {
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(form.email)) {
    return
  }
  const res = await api.getCodeWhenBindEmail({ email: form.email })
  if (res && res.code === 200) {
    emailValidateStatus.value = undefined
    emailValidateTips.value = undefined
    Message.success(t('forgetPassword.getVerifyCodeSuccessTips'))
    startTimer()
  } else {
    if (res.detail) {
      emailValidateStatus.value = 'error'
      emailValidateTips.value = res.detail
      Message.error(res.detail)
    }
  }
}

const close = () => {
  formRef.value.resetFields()
}

/**
 * 通过邮箱验证码绑定邮箱
 * @returns {Promise<void>}
 */
const submit = async () => {
  try {
    const res = await formRef.value?.validate()
    if (res) {
      // 校验失败，不关闭Modal
      return false
    }
    const response = await api.bindEmail({ email: form.email, code: form.verificationCode })
    if (response && response.code === 200) {
      Message.success(t('accountProfiles.bindEmailSuccess'))
      emit('success')
      return true
    } else {
      if (response.detail) {
        // Message.error(response.detail)
        return false
      }
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

defineExpose({
  show
})

</script>

<style scoped lang="less">

</style>
