import { initGlobalState } from 'qiankun'
export const initialState = {
  is401: false,
  locale: '',
  isMoment: false,
  messageNum: 0,
  isMessagePanelShow: false
}
const actions = initGlobalState(initialState)

export const globalStateChange = (callback) => {
  actions.onGlobalStateChange((newState, prev) => {
    // state: 变更后的状态; prev 变更前的状态
    for (const key in newState) {
      initialState[key] = newState[key]
    }
    callback(newState, prev)
  })
}

// 定义一个获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部
  return key ? initialState[key] : initialState
}

actions.onGlobalStateChange((newState, prev) => {
  // state: 变更后的状态; prev 变更前的状态
  for (const key in newState) {
    initialState[key] = newState[key]
  }
})

export default actions
