<template>
  <ArcoModal
    :title="$t('accountProfiles.editInfoTitle')"
    title-align="start"
    v-model:visible="visible"
    :on-before-ok="submit"
    @before-close="close"
    :ok-text="$t('accountProfiles.save')"
    :cancel-text="$t('accountProfiles.cancel')">
    <ArcoForm
      :model="form"
      label-align="left"
      ref="formRef">
      <ArcoFormItem
        hide-asterisk
        field="avatar"
        :label="$t('accountProfiles.editAvatar')">
        <ArcoSpace size="medium">
          <!--  图片上传组件  -->
          <ArcoUpload
            :show-file-list="false"
            accept=".jpg,.jpeg,.png"
            :on-before-upload="beforeUpload"
            :custom-request="handleUpload"
          >
            <template #upload-button>
              <div :class="`arco-upload-list-item`">
                <!--  头像显示  -->
                <Avatar
                  :size="64"
                  :name="form.name"
                  :src="form.logo"
                  isEdit
                />
              </div>
            </template>
          </ArcoUpload>

          <!--  上传提示  -->
          <ArcoTypographyText type="secondary" :style="{ fontSize: '12px' }">
            {{ $t('accountProfiles.editAvatarTip') }}
          </ArcoTypographyText>
        </ArcoSpace>
      </ArcoFormItem>
      <ArcoFormItem
        hide-asterisk
        field="name"
        :label="$t('accountProfiles.editNickname')"
        validate-trigger="input"
        :rules="[
          {
            required: true,
            message: $t('accountProfiles.editNicknameTip'),
          },
        ]">
        <ArcoInput
          v-model="form.name"
          :placeholder="$t('accountProfiles.editNicknameTip')"
          allow-clear/>
      </ArcoFormItem>
    </ArcoForm>
  </ArcoModal>
</template>

<script setup>
import { reactive, ref, defineExpose } from 'vue'
import {
  Modal as ArcoModal,
  Form as ArcoForm,
  FormItem as ArcoFormItem,
  Upload as ArcoUpload,
  Input as ArcoInput,
  TypographyText as ArcoTypographyText,
  Space as ArcoSpace,
  Message
} from '@arco-design/web-vue'
import Avatar from '@/components/user/Avatar.vue'
import api from '@/services/api'
import SparkMD5 from 'spark-md5'
import config from '@/config'
import { USERS } from '@/store/type'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()

const formRef = ref()

const visible = ref(false)

const form = reactive({
  logo: '',
  name: ''
})

const show = (name, logo) => {
  visible.value = true
  form.logo = logo
  form.name = name
}

const beforeUpload = async (file) => {
  if (!(/^image\/[jpeg|png|jpg]/.test(file.type))) {
    Message.error(t('accountProfiles.avatarError'))
    return false
  }
  const limitSize = file.size < 1024 * 1024 * 4
  if (!limitSize) {
    // 文件不可大于 10MB!
    Message.error(t('accountProfiles.sizeLimit'))
    return false
  }
  return true

  // try {
  //   const path = await conteactPhoto(option.file)
  //   const res = await api.user.updateUserInfo({
  //     headAvatar: path
  //   })
  //   if (res.code === 200) {
  //     updateInfoSuccess()
  //     Message.success('修改头像成功')
  //   }
  // } catch (error) {
  //   console.error(error)
  // }
}

const handleUpload = async (option) => {
  const { fileItem } = option
  const { file } = fileItem

  try {
    const path = await conteactPhoto(file)
    // const res = await api.user.updateUserInfo({
    //   headAvatar: path
    // })
    if (path) {
      form.logo = path
    }
  } catch (error) {
    console.error(error)
  }
}

const conteactPhoto = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    const spark = new SparkMD5() // 创建md5对象（基于SparkMD5）
    if (file.size > 1024 * 1024 * 10) {
      const data1 = file.slice(0, 1024 * 1024 * 10) // 将文件进行分块 file.slice(start,length)
      fileReader.readAsBinaryString(data1) // 将文件读取为二进制码
    } else {
      fileReader.readAsBinaryString(file)
    }

    // 文件读取完毕之后的处理
    // a639e28526d1809745b46bf1189594fe  6d9efe0c593b1383482feb229318e03a
    fileReader.onload = async (e) => {
      spark.appendBinary(e.target.result)
      const md5 = spark.end()
      console.log(md5, file)
      const list = file.name.split('.')
      const res = await upload(list[list.length - 1]?.toUpperCase(), md5, file)
      if (res) {
        resolve(res)
      } else {
        reject(new Error('false'))
      }
    }
  })
}

// 获取oss url
const upload = async (type, hash, file) => {
  try {
    const res = await api.moment.getWatermarkOssUrl({
      type,
      hash
    })
    if (res.code === 200) {
      const data = res.data
      const result = await fetch(data.uploadUrl, {
        headers: data.headers,
        method: data.method,
        body: file
      })
      if (result.status === 200) {
        return config.PHOTO_APP_IMG_BASE_URL + data.path
        // state.upHash = hash
        // state.fileList = [{
        //   hash: hash,
        //   aliasName: file.name,
        //   path: config.IMG_BASE_URL + state.ossObj.path,
        //   file
        // }]
      }
    } else if (res.code === 20005) {
      // filetoBase64(file, (url) => {
      //   state.fileList = [{
      //     hash: hash,
      //     aliasName: file.name,
      //     path: url,
      //     file
      //   }]
      // })
    } else if (res.code === 400) {
      Message.error(t('accountProfiles.avatarError'))
    }
  } catch (error) {
    console.error(error)
  }
}

const updateInfoSuccess = () => {
  store.dispatch('user/' + USERS.SET_USER_INFO)
}

const submit = async () => {
  const res = await formRef.value?.validate()
  if (res) {
    // 校验失败，不关闭Modal
    return false
  }
  console.log('test')
  try {
    const response = await api.user.updateUserInfo({
      headAvatar: form.logo,
      nickName: form.name
    })
    if (response?.code === 200) {
      updateInfoSuccess()
      Message.success(t('accountProfiles.changeSuccess'))
      return true
    }
  } catch (e) {
    console.error(e)
    return false
  }
}

const close = () => {
  formRef.value.resetFields()
}

defineExpose({
  show
})
</script>

<style scoped lang="less">

</style>
