import { USERS, CLIENT } from '../type'
import api from '@/services/api'

const state = {
  userInfo: null
}

const getters = {
}

const mutations = {
  [USERS.SET_USER_INFO] (state, userInfo) {
    state.userInfo = userInfo
  }
}

const actions = {
  [USERS.SET_USER_INFO] ({ commit, state, rootState }, { callback, noOrgIdCallback } = {}) {
    // 修改为新版本的用户信息接口
    api.user.getInfoV2().then(res => {
      if (res.code === 200) {
        const data = { user: { ...res.data }, configs: res.data.configs }
        const configs = data.configs
        // const configs = []
        commit(USERS.SET_USER_INFO, data.user)
        if (configs) {
          // 是否有默认企业
          let bool = false
          configs.map(item => {
            switch (item.type) {
              case '0':
                // 默认企业
                bool = true
                commit('enterprise/' + CLIENT.ENTERPRISE.SET_SELECTED, Number(item.value), { root: true })
                if (callback) callback(Number(item.value))
                break

              default:
                break
            }
          })
          if (!bool) {
            // debugger
            if (noOrgIdCallback) noOrgIdCallback()
          }
        }
      }
    })
  }
}

export default {
  namespaced: true, // 为了解决不同模块命名冲突的问题，将不同模块的namespaced:true
  state,
  getters,
  actions,
  mutations
}
