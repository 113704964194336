import dayjs from 'dayjs'
const toTime = (date) => {
  if (date) {
    if (dayjs.unix(date).isValid()) {
      return dayjs.unix(date).format('YYYY-MM-DD HH:mm')
    } else {
      return date
    }
  } else {
    return '-'
  }
}
export default {
  toTime
}
