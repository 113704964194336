import { createStore } from 'vuex'
import { COMMON } from './type'
import user from './module/user'
import enterprise from './module/enterprise'
import router from '@/router/index.js'

export default createStore({
  state: {
    locale: '',
    breakpoint: 0,
    token: localStorage.getItem('token') || '',
    breakpointList: {
      xs: 480,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1600
    },
    adminNavTitle: '',
    isMessageModalShow: false,
    isHeaderHidden: false,
    isLogoHidden: false,
    cdeToken: null
  },
  getters: {
    fullToken: state => {
      return state.token ? 'Bearer ' + state.token : ''
    }
  },
  mutations: {
    [COMMON.SET_LOCALE] (state, payload) {
      state.locale = payload
    },
    SET_TOKEN (state, payload) {
      state.token = payload
      localStorage.setItem('token', payload)
    },
    SET_CDE_TOKEN (state, payload) {
      state.cdeToken = payload
      localStorage.setItem('cdeToken', payload)
    },
    [COMMON.SET_BREAKPOINT] (state, breakpoint) {
      state.breakpoint = breakpoint
    },
    SET_ADMIN_NAV_TITLE (state, payload) {
      state.adminNavTitle = payload
    },
    [COMMON.SET_IS_MESSAGE_MODAL_SHOW] (state, payload) {
      state.isMessageModalShow = payload
    },
    [COMMON.SET_HEADER_HIDDEN] (state, payload) {
      state.isHeaderHidden = payload
    },
    [COMMON.SET_LOGO_HIDDEN] (state, payload) {
      state.isLogoHidden = payload
    }
  },
  actions: {
    [COMMON.SET_LOCALE] ({ commit, state }, locale) {
      commit(COMMON.SET_LOCALE, locale)
    },
    setToken ({ commit, state }, token) {
      commit('SET_TOKEN', token)
    },
    setCDEToken ({ commit, state }, cdeToken) {
      commit('SET_CDE_TOKEN', cdeToken)
    },
    logout  ({ commit, state }) {
      commit('SET_TOKEN', '')
      commit('SET_CDE_TOKEN', '')
      if (!router.currentRoute.meta || !router.currentRoute.meta.noAuthentication) {
        router.replace({
          name: 'Login',
          query: { redirect: router.currentRoute.fullPath }
        })
      }
    },
    [COMMON.SET_BREAKPOINT] ({ commit, state }, breakpoint) {
      commit(COMMON.SET_BREAKPOINT, breakpoint)
    },
    setAdminNavtitleAction ({ commit, state }, adminNavTitle) {
      commit('SET_ADMIN_NAV_TITLE', adminNavTitle)
    },
    [COMMON.SET_IS_MESSAGE_MODAL_SHOW] ({ commit, state }, isMessageModalShow) {
      commit(COMMON.SET_IS_MESSAGE_MODAL_SHOW, isMessageModalShow)
    },
    [COMMON.SET_HEADER_HIDDEN] ({ commit, state }, isHeaderHidden) {
      commit(COMMON.SET_HEADER_HIDDEN, isHeaderHidden)
    },
    [COMMON.SET_LOGO_HIDDEN] ({ commit, state }, isLogoHidden) {
      commit(COMMON.SET_LOGO_HIDDEN, isLogoHidden)
    }
  },
  modules: {
    user,
    enterprise
  }
})
