import { $get, $post, $delete } from '../http'
import config from '@/config'
export default {
  /**
   * 获取项目列表
   * 原接口废弃：/core/org/project/list
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getProjectList: (data = {}) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/project/list', data)
  },

  /**
   * 获取项目成员列表
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getProjectMemberList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/user/list', data)
  },

  /**
   * 同步成员到Jepass
   *
   * TODO 废弃，删除使用的地方
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  postUsersToJP: (data = {}) => {
    return $post(config.EYEGLE_EYE + '/eeJpForm/importJpUser', data)
  },

  /**
   * 获取项目角色关系
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getProjectUserRoleList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/role/user/relation/list', data)
  },

  /**
   * 绑定用户的项目角色
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  bindProjectUserRole: (data = {}) => {
    return $post(config.BASE_URL + '/core/project/role/bind/user', data)
  },

  /**
   * 绑定用户的项目角色
   *
   * TODO 废弃
   * @deprecated
    * @param data
   * @param param
   * @returns {Promise | Promise<unknown>}
   */
  unBindProjectUserRole: (data = {}, param = {}) => {
    return $delete(config.BASE_URL + '/core/project/role/unbind/user', data, param)
  },

  /**
   * 获取项目角色列表
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getProjectRoleList: (data = {}) => {
    return $get(config.BASE_URL + '/core/project/role/list', data)
  },

  /**
   * 移除项目人员
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @param param
   * @returns {Promise | Promise<unknown>}
   */
  removeProjectRole: (data = {}, param = {}) => {
    return $delete(config.BASE_URL + '/core/project/user', data, param)
  }
}
