import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Antd from 'ant-design-vue'
import i18n from './locale/i18n'
import api from '@/services/api'
import config from './config'
import 'ant-design-vue/dist/antd.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@arco-design/web-vue/dist/arco.css'
import './styles/reset.less'
import './styles/base.less'
import { createFromIconfontCN } from '@ant-design/icons-vue'
import utils from '@/utils/gobalFunc'

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
}

api.getConfig().then(res => {
  for (const key in res) {
    const element = res[key]
    config[key] = element
  }
  // console.log(config)
  if (res.WEB_TITLE) document.title = res.WEB_TITLE
}).catch(err => {
  console.error(err)
}).finally(() => {
  const app = createApp(App)
  app.config.globalProperties.$utils = utils
  app.config.globalProperties.$logo = config.LOGO
  const IconFont = createFromIconfontCN({
    scriptUrl: config.ICON_FONT_PATH
  })
  app.component('IconFont', IconFont)

  app.use(store)
    .use(router)
    .use(Antd)
    .use(ElementPlus)
    .use(i18n)
    .mount('#app')
})
