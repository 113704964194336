<template>
  <a-layout class="apps-layout-con">
    <a-layout-header class="apps-layout-header flex flex-j-c-sb" v-if="currentProject && currentProject.projectName" v-show="!($store.state.isHeaderHidden)">
      <div class="header-left-con">
        <div v-show="!($store.state.isLogoHidden)" style="display: flex">
          <img :src="$logo || '/images/logo.png'" v-if="!isBreakpoint" height="28" alt="Logo" @click="backToHome" style="cursor:pointer;">
          <img :src="$logo || '/images/simple-logo.png'" v-if="(breakpoint > breakpointList.xs) && (breakpoint < breakpointList.lg)" height="28" alt="Logo" @click="backToHome" style="cursor:pointer">
          <span class="divide-line" v-if="!isBreakpoint"></span>
        </div>
        <div style="line-height:initial">
          <span class="home-page grey-button" @click="backToHome">
            <span><HomeOutlined /></span>
            <span style="margin-left: 6px" v-if="!isBreakpoint">{{$t('apps.mainPage')}}</span>
          </span>
        </div>
        <a-dropdown :trigger="['click']" v-if="!isBreakpoint">
          <div style="line-height:initial">
            <span class="project-selection grey-button">
              <span>{{currentProject && currentProject.projectName}}</span>
              <span style="font-size:10px;margin-left:3px"><CaretDownOutlined/></span>
            </span>
          </div>
          <template #overlay>
            <a-menu
              @click="handleMenuClick"
              style="max-height: 60vh;overflow: auto">
              <a-menu-item
                v-for="(item) in projectList"
                :key="item.projectId">
                <div :style="currentProject && currentProject.projectId === item.projectId ? `color: #0091FF` : ''">{{item.projectName}}</div>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
        <a-menu
          v-if="appList && appList.length"
          v-model:selectedKeys="activeKey"
          mode="horizontal"

          @click="onTabSelect"
          style="display: inline-block;margin-left: 18px;"
          class="app-list">
          <!-- <a-menu-item
            v-for="(item,idx) in appList"
            :key="item.appId">
            <template #icon>
              <IconFont  :type='item.menuLogo'/>
            </template>
            <span>
              <span class="tab-text">{{item.name || item.groupSign}}</span>
              <span
                :class="item.userFavor ? '' : 'non-f'"
                class="collection-icon">
                <StarOutlined class="non-collect-icon" @click.stop="changeCollection(item,idx)" v-show="!item.userFavor"/>
                <StarFilled
                  :class="item.userFavor ? '' : 'non-f'"
                  class="collect-icon"
                  style="color:#FFD000;margin-left: 0px;"
                  @click.stop="changeCollection(item,idx)"/>
              </span>
            </span>
          </a-menu-item> -->
          <a-menu-item
            v-for="(item) in appList"
            :key="item.appId">
            <template #icon>
              <IconFont  :type='item.icon'/>
            </template>
            <span>
              <span class="tab-text">{{getAppStr(item)}}</span>
              <!-- 暂时隐藏收藏按钮 -->
              <!-- <span
                :class="item.userFavor ? '' : 'non-f'"
                class="collection-icon">
                <StarOutlined class="non-collect-icon" @click.stop="changeCollection(item,idx)" v-show="!item.userFavor"/>
                <StarFilled
                  :class="item.userFavor ? '' : 'non-f'"
                  class="collect-icon"
                  style="color:#FFD000;margin-left: 0px;"
                  @click.stop="changeCollection(item,idx)"/>
              </span> -->
            </span>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="header-right-con">
        <!-- 项目设置 -->
        <!-- <a-tooltip placement="topLeft" :title="$t('header.projectSetting')" arrow-point-at-center>
          <IconFont  type='icon-shezhi' class="header-icon" @click="toProjectSetting"/>
        </a-tooltip> -->
        <a-tooltip placement="topLeft" :title="$t('header.message')" arrow-point-at-center v-if="isMessageShow">
          <a-badge :count="messageNum" :overflow-count="99">
            <IconFont  type='icon-tongzhi' class="header-icon" @click="showMessage"/>
          </a-badge>
        </a-tooltip>
        <HeaderAction :isBreakpoint="isBreakpoint"/>
        <!-- <div class="team-button">
          <div
            class="team-member"
            @click="isMemberShow = true">
            <span class="team-icon"><TeamOutlined /></span>
            <span class="team-text">{{$t('apps.projectMember')}}</span>
          </div>
          <div
            class="member-invite"
            @click="inviteMember">
            <span class="team-icon"><UserAddOutlined /></span>
            <span class="team-text">{{$t('apps.invite')}}</span>
          </div>
        </div> -->
      </div>
    </a-layout-header>
    <a-layout style="display: none;">
      <a-layout-sider
        :width="0"
        class="client-layout-sider"
        breakpoint="lg"
        collapsed-width="0"
        @breakpoint="onBreak">
      </a-layout-sider>
    </a-layout>
    <a-layout class="apps-layout-content-wrap">
      <a-layout-content class="apps-layout-content">
        <!-- <router-view/> -->
        <a-spin tip="Loading..."
          style="position: absolute;left: 50%;top: 50%;z-index:10;transform: translate(-50%, -50%);"
          v-show="showLoading">
        </a-spin>
        <!-- <keep-alive> -->
          <div v-if="!showIFrame" id="app-container">
            <a-empty v-if="appList && !appList.length" class="empty" description="暂无应用"/>
          </div>
          <iframe v-else :src="iframeUrl" class="iframe-sty" allow="fullscreen"/>
        <!-- </keep-alive> -->
        <a-drawer
          v-model:visible="isMemberShow"
          placement="right"
          width="400"
          :getContainer="getDrawerCon"
          style="position:absolute;width:400px"
          :closable="false"
          :mask="false">
          <template #title class="member-header">
            <div class="header-title">
              <span>{{$t('apps.allMember')}}</span>
              <span style="font-size:10px;margin-left:5px"><CaretDownOutlined /></span>
            </div>
          </template>
          <template #extra>
            <div
              class="close-icon"
              @click="closeDrawer">
              <CloseOutlined />
            </div>
          </template>
          <div class="member-con">
            <div class="add-member">
              <span class="add-icon"><PlusCircleFilled /></span>
              <span class="add-text">{{$t('apps.addMember')}}</span>
            </div>
            <div
              v-for="(member, index) in memberList"
              :key="index"
              class="member-item">
              <div class="member-content">
                <a-avatar :src="member.headAvatar" class="member-avatar" style="margin-right: 8px;" :size="36"/>
                <div class="member-detail">
                  <div class="member-name">
                    {{member.nickName}}
                  </div>
                  <div class="member-email">
                    {{member.email}}
                  </div>
                </div>
              </div>
              <a-dropdown
                :trigger="['click']"
                overlayClassName="setting-popover"
                class="setting-popover"
                 v-model:visible="member.visible"
                 @visibleChange="(val) => {onShowRole(val, member)}">
                <div
                  class="member-action"
                  :style="member.visible ? 'display:flex' : ''">
                  <CaretDownOutlined />
                </div>
                <template #overlay>
                  <a-menu
                    v-if="member.visible"
                    style="min-width: 200px;"
                    class="setting-con">
                    <div class="setting-member-title">
                      <div class="title-text">
                        {{$t('apps.memberSetting')}}
                      </div>
                      <div
                        class="title-icon"
                        @click="member.visible = false">
                        <CloseOutlined />
                      </div>
                    </div>
                    <div class="divide-line"></div>
                    <a-menu-item
                      v-for="(item) in roleList"
                      :key="item.projectRoleId">
                      <div
                        class="role-item"
                        @click="clickRole(item)">
                        <div class="role-name">
                          {{item.projectRoleName}}
                        </div>
                        <div class="selected-icon" v-show="item.selected">
                          <CheckOutlined />
                        </div>
                      </div>
                    </a-menu-item>
                    <div class="divide-line"></div>
                    <div class="setting-member-footer">
                      <div class="remove-icon"><ExportOutlined /></div>
                      <div class="remove-text">{{$t('apps.removeMember')}}</div>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
        </a-drawer>
      </a-layout-content>
    </a-layout>
  </a-layout>
  <ShareDialog
    :shareLink="''"
    :visible="isInviteModalShow"
    :toDate="toDate"
    @cancel="isInviteModalShow = false"/>
</template>
<script>
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs, watch, watchEffect } from 'vue'
import {
  CaretDownOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
  HomeOutlined,
  PlusCircleFilled
} from '@ant-design/icons-vue'
import ShareDialog from '@/components/ShareDialog.vue'
import { useRoute, useRouter } from 'vue-router'
import { admin, client } from '@/router/path'
import api from '@/services/api'
import dayjs from 'dayjs'
import * as localizedFormat from 'dayjs/plugin/localizedFormat'
import HeaderAction from '@/components/business/HeaderAction.vue'
import { addGlobalUncaughtErrorHandler, loadMicroApp } from 'qiankun'
import actions, { globalStateChange } from '@/qiankun/action'
import { mapState, useStore } from 'vuex'
import config from '@/config'
import { useI18n } from 'vue-i18n'
// import { message } from 'ant-design-vue'
// import AMap from 'AMap'
// import 'zone.js'
export default defineComponent({
  name: 'index',
  components: {
    // UserAddOutlined,
    // TeamOutlined,
    CaretDownOutlined,
    HomeOutlined,
    ShareDialog,
    CloseOutlined,
    PlusCircleFilled,
    ExportOutlined,
    CheckOutlined,
    // StarOutlined,
    // StarFilled,
    HeaderAction
    // BellOutlined
  },
  props: {},
  computed: {
    ...mapState([
      'breakpoint',
      'breakpointList'
    ])
  },
  setup (props, context) {
    addGlobalUncaughtErrorHandler(event => console.error('子应用异常', event))
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t, te } = useI18n()
    // const store = useStore()
    dayjs.extend(localizedFormat)

    let state = reactive({
      // 是否展示iframe
      showIFrame: false,
      // iframe src
      iframeUrl: '',
      toDate: '',
      isMemberShow: false,
      isInviteModalShow: false,
      isBreakpoint: false,
      activeKey: [''],
      appList: null,
      projectList: [
      ],
      memberList: [
      ],
      roleList: [
      ],
      showLoading: false,
      microApp: null,
      isMessageShow: false,
      messageNum: null,
      basePath: 'https://dev-ocean-eagle.jarvisbim.com.cn/'
    })

    let iTimer = null

    const currentProject = computed(() => {
      const filter = state.projectList.filter(item => {
        return item.projectId === route.params.projectId
      })
      return filter && filter[0] ? filter[0] : null
    })
    // let microApp = null

    const checkArea = (isInland) => {
      const search = window.location.search && window.location.search.length ? window.location.search : null
      const jumpUrl = config[isInland ? 'INLAND_URL' : 'FOREIGN_URL'] + window.location.pathname + `${search ? search + '&' : '?'}token=${store.state.token}`
      // window.open(jumpUrl, '_self')
      window.location.replace(jumpUrl)
    }

    // TODO:暂时停用Amap
    // const getCity = () => {
    //   const citySearch = new AMap.CitySearch()
    //   citySearch.getCityByIp((status, result) => {
    //     let isInland = false
    //     if (status === 'complete' && result.info === 'OK') {
    //       if (result && result.adcode && config.FOREIGN_LIST && !(config.FOREIGN_LIST.includes(result.adcode))) {
    //         isInland = true
    //       }
    //     } else {
    //       return
    //     }
    //     const local = window.location.origin
    //     if (!config.FOREIGN_URL || !config.INLAND_URL || !config.FOREIGN_LIST) return
    //     if ((!isInland && (local !== config.FOREIGN_URL)) || (isInland && (local === config.FOREIGN_URL))) {
    //       checkArea(isInland)
    //     }
    //   })
    // }
    onMounted(async () => {
      // TODO:暂时停用Amap
      // getCity()
      window.addEventListener('beforeunload', async e => {
        if (state.microApp) {
          await state.microApp.unmount()
          state.microApp = null
        }
        state = null
        window.removeEventListener('beforeunload', () => {})
      })
      getProjectList()
      init()
    })

    const init = async () => {
      // state.activeKey = [route.name]
      getAppsList()
      // await getMemberList()
      // 后续版本会去除 JEPass
      // postUsersToJP()
    }

    // const postUsersToJP = async () => {
    //   const { orgId, projectId } = route.params
    //   try {
    //     const res = await api.project.postUsersToJP({
    //       projectId,
    //       orgId,
    //       userList: state.memberList
    //     })
    //     if (res && res.code === 200) {
    //       console.log(res)
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   }
    //   console.log(state.memberList)
    // }

    const toProjectSetting = () => {
      router.push({
        name: admin.project.PROJECT_MEMBERS,
        params: { ...route.params }
      })
    }

    /**
     * 替换url中的params
     * @param urlTemplate
     * @param paramMap
     */
    const replaceUrlParams = (urlTemplate, paramMap) => {
      return urlTemplate.replace(/{{(.*?)}}/g, (match, p1) => {
        const paramKey = p1.trim()
        const paramValue = paramMap.get(paramKey)
        // 如果参数值存在则替换，否则设置为空
        return paramValue !== undefined ? paramValue : ''
      })
    }

    const onTabSelect = ({ key }) => {
      // router.replace(key)
      if (state.activeKey[0] === key) return
      state.showLoading = true
      state.activeKey = [key]
      for (let index = 0; index < state.appList.length; index++) {
        const element = state.appList[index]
        // 加载微应用
        if (key === element.appId) {
          router.replace({
            name: route.name,
            params: {
              ...route.params
            },
            query: {
              ...route.query,
              appId: key
            }
          }).then(async () => {
            if (state.microApp) {
              window.location.reload()
              await state.microApp.unmount()
              state.microApp = null
            }
            state.showIFrame = false
            // 若为PPT应用，则拼接url，使用iframe打开
            // 或者是新版的 frameApp 应用
            // ！！！注意，修改时，watch() 也需要同步修改
            if (element.groupSign === 'jarvisPPT' || element.groupSign === 'frameV2App') {
              // 参数总表
              const urlQueryMap = new Map()
              // token
              urlQueryMap.set('token', store.state.token)
              // locale（zhTW、zhTW、enUS）
              urlQueryMap.set('locale', store.state.locale)
              // userId
              urlQueryMap.set('userId', store.state.user?.userInfo?.userId ?? '')
              // orgId
              urlQueryMap.set('orgId', route.params.orgId)
              // projectId
              urlQueryMap.set('projectId', route.params.projectId)
              // appId
              urlQueryMap.set('appId', element.appId)
              // projectAppId
              urlQueryMap.set('projectAppId', element.projectAppId)
              // annotationId
              urlQueryMap.set('annotationId', route.query.annotationId)

              let localeV2
              switch (store.state.locale) {
                case 'enUS':
                  localeV2 = 'en'
                  break
                default:
                  localeV2 = 'zh'
                  break
              }
              // 可选英文，其他选项均为中文
              // localeV2（zh、en）
              urlQueryMap.set('localeV2', localeV2)

              const template = element.configs.link
              const paramsUrl = replaceUrlParams(template, urlQueryMap)

              state.showIFrame = true
              state.showLoading = false
              state.iframeUrl = paramsUrl
              return
            }
            if (element.configs) {
              /* 查找需要用到的配置 */
              // 查找微应用路径的配置
              // const entryFilter = element.configs.filter(i => i.entitySign === 'entry')
              const entryFilter = element.configs.entry
              // 查找微应用formId的位置
              // const formIdFilter = element.configs.filter(i => i.entitySign === 'formId')
              const formIdFilter = element.configs.formId
              // 查找iframe地址
              // const iframeFilter = element.configs.filter(i => i.entitySign === 'link')
              const iframeFilter = element.configs.link
              // 查找是否显示模型属性,超真实模型app用到
              // const showModelAttrsFilter = element.configs.filter(i => i.entitySign === 'showModelAttrs')
              const showModelAttrsFilter = element.configs.showModelAttrs
              if (entryFilter) {
                // 获取userId，目前发现动态应用用到
                api.user.getInfoV2().then((res) => {
                  const userInfo = res.data
                  const entryUrl = (config.BASE_APP_PATH || state.basePath) + entryFilter
                  // debugger
                  const props = {
                    token: store.state.token,
                    cdeToken: store.state.cdeToken,
                    projectAppId: element.projectAppId,
                    ...route.params,
                    ...route.query,
                    locale: store.state.locale,
                    // publicPath: entryFilter[0].value.split('#/')[0],
                    publicPath: config.DEVELOPMENT ? config.DEBUGGER_APP_URL.split('#/')[0] : entryFilter.split('#/')[0],
                    // publicPath: entryUrl.split('#/')[0],
                    langSign: element.sign,
                    // userInfo: store.state.user.userInfo // 这时vuex的userInfo不一定已经拿到了
                    userInfo
                  }
                  if (formIdFilter) props.jeFormId = formIdFilter
                  if (iframeFilter) props.iframeLink = iframeFilter
                  if (showModelAttrsFilter) props.showModelAttrs = showModelAttrsFilter
                  // dms应用参数
                  if (element.groupSign === 'dmsApp') {
                    if (element.custom?.project) {
                      props.cdeOrgId = element.custom?.project.orgId
                      props.cdeProjectId = element.custom?.project.projectId
                    }
                  }
                  // iframe应用参数
                  if (element.groupSign === 'frameApp') {
                    if (element.custom?.project) {
                      props.urlQuery = element.custom?.project
                    }
                  }
                  state.microApp = loadMicroApp({
                    name: element.groupSign, // 应用标识
                    // entry: entryUrl,
                    entry: config.DEVELOPMENT ? config.DEBUGGER_APP_URL : entryUrl, // 应用入口
                    container: '#app-container', // 容器
                    props //  子应用加载时传给子应用的参数
                  }, {
                    sandbox: {
                      experimentalStyleIsolation: true // 当 experimentalStyleIsolation 被设置为 true 时，qiankun 会改写子应用所添加的样式为所有样式规则增加一个特殊的选择器规则来限定其影响范围
                    }
                  })

                  // nextTick(() => {
                  //   console.log()
                  //   router.replace({
                  //     path: '/' + route.params.orgId + '/' + route.params.projectId + '/' + element.groupSign,
                  //     query: route.query
                  //   })
                  // })

                  // 微应用加载成功时把loading去掉
                  let count = 0
                  iTimer = setInterval(() => {
                    const status = state.microApp.getStatus()
                    count++
                    if (status === 'MOUNTED' || count >= 20) {
                      state.showLoading = false
                      clearInterval(iTimer)
                      iTimer = null
                    }
                  }, 500)

                  // onGlobalStateChange(监听全局状态，有变更触发 callback)
                  globalStateChange((s, prev) => {
                  // 子应用传来通知 Token失效
                  // debugger
                    // console.log(s)
                    if (s.is401) {
                      store.dispatch('logout')
                    }
                    if (s.isMoment) {
                      state.isMessageShow = true
                    }
                    if (s.messageNum) {
                      state.messageNum = s.messageNum
                    }
                  })
                  const query = JSON.parse(JSON.stringify(route.query))
                  // delete query.annotationId
                  router.replace({ query })
                })
              }
            }
          })
        }
      }
    }

    const onBreak = (val) => {
      state.isBreakpoint = val
    }

    // 切换项目
    const handleMenuClick = ({ key }) => {
      if (currentProject.value && currentProject.value.projectId === key) return
      router.replace({
        name: route.name,
        params: {
          ...route.params,
          projectId: key
        }
      }).then(() => {
        window.location.reload()
      })
    }

    const inviteMember = () => {
      state.toDate = dayjs().add(1, 'month').format('LL LT')
      state.isInviteModalShow = true
    }

    const getDrawerCon = () => {
      return document.querySelector('.apps-layout-content')
    }

    const clickRole = async (item) => {
      // item.selected = !item.selected
      const { orgId, projectId } = route.params
      if (item.selected) {
        const res = await api.project.unBindProjectUserRole({
          userId: item.userId,
          projectRoleId: item.projectRoleId
        }, {
          orgId,
          projectId
        })
        if (res.code === 200) {
          item.selected = false
        }
      } else {
        const res = await api.project.bindProjectUserRole({
          orgId,
          projectId
        }, {
          userId: item.userId,
          projectRoleId: item.projectRoleId
        })
        if (res.code === 200) {
          item.selected = false
        }
      }
    }

    const closeDrawer = () => {
      state.isMemberShow = false
    }

    // const changeCollection = async (item, idx) => {
    //   // event.stopPropagation()
    //   // item.collected = !item.collected
    //   try {
    //     const { orgId, projectId } = route.params
    //     const { appId } = item
    //     if (item.userFavor) {
    //       const res = await api.app.deleteFavApp({ appId, projectId, orgId })
    //       if (res && res.code === 200) {
    //         message.success(t('project.deleteAppSuccess'))
    //         state.appList[idx].userFavor = false
    //       }
    //     } else {
    //       const res = await api.app.favApp({ appId, projectId, orgId })
    //       if (res && res.code === 200) {
    //         message.success(t('project.favAppSuccess'))
    //         state.appList[idx].userFavor = true
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //   }
    // }

    const backToHome = () => {
      router.push({
        name: client.PROJECT_LIST,
        params: {
          orgId: route.params.orgId
        }
      })
    }

    // const genActiveRule = (routerPrefix) => {
    //   return location => location.pathname.startsWith(routerPrefix)
    // }

    // 获取应用列表
    const getAppsList = async () => {
      const { orgId, projectId } = route.params
      const res = await api.app.getProjectApps({ orgId, projectId })
      if (res.code === 200) {
        state.appList = res.data.list || []
        // const obj = {}
        // const list = state.appList.filter(element => {
        //   const entryFilter = element.configs.filter(i => i.entitySign === 'entry')
        //   let bool = true
        //   if (obj[element.groupSign]) bool = false
        //   obj[element.groupSign] = true
        //   return entryFilter && entryFilter.length && entryFilter[0] && entryFilter[0].value && bool
        // }).map((element, idx) => {
        //   return {
        //     name: element.groupSign,
        //     entry: config.DEVELOPMENT ? config.DEBUGGER_APP_URL : element.configs.filter(i => i.entitySign === 'entry')[0].value,
        //     container: '#app-container',
        //     activeRule: '/' + route.params.orgId + '/' + route.params.projectId + '/' + element.groupSign,
        //     props: {
        //       token: store.state.token,
        //       ...route.params,
        //       ...route.query,
        //       locale: store.state.locale,
        //       publicPath: element.configs.filter(i => i.entitySign === 'entry')[0].value.split('#/')[0]
        //     }
        //   }
        // })
        // console.log(list)
        // registerMicroApps(list)
        // // 启动 qiankun
        // if (!window.isQiankunStart) {
        //   window.isQiankunStart = true
        //   start()
        // }

        if (route.query.appId) {
          const appObj = state.appList?.find(i => Number(route.query.appId) === Number(i.appId))
          if (appObj) {
            onTabSelect({ key: Number(route.query.appId) })
            return
          }
        }
        if (state.appList && state.appList.length) {
          onTabSelect({ key: state.appList[0].appId })
        }
      }
    }

    // 获取成员列表
    // const getMemberList = async () => {
    //   const { orgId, projectId } = route.params
    //   const res = await api.project.getProjectMemberList({ orgId, projectId })
    //   if (res.code === 200) {
    //     const { users, roles } = res.data
    //     state.memberList = users
    //     state.roleList = roles
    //   }
    // }

    const getUserRole = async (item) => {
      const { orgId, projectId } = route.params
      const res = await api.project.getProjectUserRoleList({ orgId, projectId, userId: item.userId })
      if (res.code === 200) {
        const data = res.data
        if (data && data.length) {
          state.roleList = state.roleList.map(role => {
            let selected = false
            data.map(userRole => {
              if (userRole.projectRoleId === role.projectRoleId) {
                selected = true
              }
            })
            return {
              ...role,
              selected
            }
          })
        }
      }
    }

    const onShowRole = (value, item) => {
      getUserRole(item)
    }

    // 获取项目列表
    const getProjectList = async () => {
      const res = await api.project.getProjectList({
        orgId: route.params.orgId,
        m: 'loc'
      })
      if (res.code === 200) {
        // 迁移新接口，数据结构变化了
        // 将新接口的数据转换为以前的格式
        state.projectList = res.data.list.reverse().map(x => {
          return {
            projectId: x.projectId,
            projectLogo: x.logo,
            projectName: x.name,
            projectDesc: x.desc,
            orgId: router.currentRoute.value.params.orgId,
            created: x.created,
            updated: x.updated,
            memberNum: x.userQry
          }
        })
      }
    }

    const showMessage = () => {
      actions.setGlobalState({
        isMessagePanelShow: !actions.getGlobalState('isMessagePanelShow'),
        messageNum: 0
      })
      state.messageNum = 0
    }

    const getAppStr = (item) => {
      if (item?.custom?.project?.title) return item.custom.project.title
      if (te('appTitle.' + (item.sign || item.groupSign))) {
        return t('appTitle.' + (item.sign || item.groupSign))
      } else {
        return item.name
      }
    }

    watchEffect(() => {
      if (store.state.locale) {
        getAppsList()
      }
    })

    /**
     * 监听当前选中语言变化
     */
    watch(() => store.state.locale,
      () => {
        const app = state.appList.find(x => x.appId === state.activeKey[0])
        console.log(app)

        if (app.groupSign === 'jarvisPPT' || app.groupSign === 'frameV2App') {
          // 参数总表
          const urlQueryMap = new Map()
          // token
          urlQueryMap.set('token', store.state.token)
          // locale（zhTW、zhTW、enUS）
          urlQueryMap.set('locale', store.state.locale)
          // userId
          urlQueryMap.set('userId', store.state.user?.userInfo?.userId ?? '')
          // orgId
          urlQueryMap.set('orgId', route.params.orgId)
          // projectId
          urlQueryMap.set('projectId', route.params.projectId)
          // appId
          urlQueryMap.set('appId', app.appId)
          // projectAppId
          urlQueryMap.set('projectAppId', app.projectAppId)
          // annotationId
          urlQueryMap.set('annotationId', route.query.annotationId)

          let localeV2
          switch (store.state.locale) {
            case 'enUS':
              localeV2 = 'en'
              break
            default:
              localeV2 = 'zh'
              break
          }
          // 可选英文，其他选项均为中文
          // localeV2（zh、en）
          urlQueryMap.set('localeV2', localeV2)

          const template = app.configs.link
          state.iframeUrl = replaceUrlParams(template, urlQueryMap)
        }
      })

    onUnmounted(async () => {
      // window.document.createElement = Document.prototype.createElement
      if (state.microApp) {
        await state.microApp.unmount()
        state.microApp = null
      }
      actions.offGlobalStateChange()
      if (iTimer) {
        clearInterval(iTimer)
        iTimer = null
      }
      state = null
    })

    return {
      currentProject,
      onBreak,
      onTabSelect,
      handleMenuClick,
      inviteMember,
      getDrawerCon,
      clickRole,
      closeDrawer,
      backToHome,
      onShowRole,
      ...toRefs(state),
      showMessage,
      toProjectSetting,
      route,
      getAppStr
    }
  }
})
</script>
<style lang='less'>
.iframe-sty {
  width: 100%;
  height: 100%;
  border: none;
}
#app-container > div {
  width: 100%;
  height: 100%;
  &.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.apps-layout-con {
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  user-select: none;
  .apps-layout-header {
    padding: 0 20px;
    width: 100%;
    height: 64px;
    background: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 9;
    .header-left-con {
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      .divide-line {
        height: 28px;
        border-left: solid 1px rgba(0,0,0,0.2);
        margin-left: 4px;
      }
      .ant-menu {
          width: calc(100% - 100px);
          min-width: 20px;
      }
      &-lg {
        width: calc(100% - 180px);
        .ant-menu {
          width: calc(100% - 50px);
        }
      }
      .home-page {
        margin: 0 8px;
      }
      .project-selection {
      }
      .grey-button {
        padding: 4px 8px;
        white-space: nowrap;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        // text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.05);
        // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
      .app-list {
        .ant-menu-item {
          .tab-text {
            // padding-right: 26px;
          }
          .collection-icon {
            margin-left: 5px;
            cursor: pointer;
            position: absolute;
            line-height: 14px;
            top: 50%;
            // right: 26px;
            transform: translateY(-50%);
          }
          &:hover {
            .collection-icon {
              .non-collect-icon {
                color: black;
              }
              display: inline-block;
            }
          }
        }
        .ant-menu-item-active {
          .tab-text {
            color: #008CFF;
          }
        }
        .non-f {
          display: none;
        }
      }
    }
    .header-right-con {
      display: flex;
      align-items: center;
      .header-icon {
        margin-left: 24px;
        cursor: pointer;
      }
      .header-icon-lg {
        margin-left: 8px;
      }
      .team-button {
        display: flex;
        white-space: nowrap;
        line-height: initial;
        border: solid 1px #008CFF;
        border-radius: 5px;
        margin-left: 25px;
      }
      .team-member {
          padding: 4px 8px;
          color: #008FFC;
          cursor: pointer;
      }
      span.team-icon {
          margin-right: 4px;
      }
      .member-invite {
          padding: 4px 8px;
          background: #008FFC;
          color: white;
          cursor: pointer;
      }
    }
  }
  .apps-layout-content-wrap {
    // padding-top: 10px;
    width: 100%;
    height: calc(100% - 64px);
    position: relative;
    overflow: hidden;
    .apps-layout-content {
    //   padding: 0 20px;
      background: #fff;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      #app-container {
        width: 100%;
        height: 100%;
        position: relative;
        top: 1px;

      }
      .close-icon {
        cursor: pointer
      }
      .member-con {
        .add-member {
          /* padding: 8px 0px; */
          display: flex;
          align-items: center;
          color: #008FFC;
          cursor: pointer;
        }
        span.add-icon {
          font-size: 36px;
          margin-right: 6px;
        }
        .member-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 0;
          .setting-popover {
            .ant-popover-inner-content {
              padding:0
            }
          }
          .member-content {
            display: flex;
            align-items: center;
            .member-email {
              font-size: 12px;
            }
          }
          &:hover {
            .member-action {
              display: flex;
              &:focus {
                display: flex;
              }
            }
          }
          .member-action {
            display: none;
            font-size: 10px;
            width: 16px;
            height: 16px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 2px;
            // display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.setting-con {
  .setting-member-title {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      padding: 14px 16px;
      font-size: 16px;
      .title-icon {
          cursor: pointer;
      }
  }
  .role-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 4px;
      cursor: pointer;
      .selected-icon {
          color: #008FFC;
      }
  }
  .setting-member-footer {
      display: flex;
      /* justify-content: center; */
      color: #FF5E5E;
      padding: 14px 16px;
      cursor: pointer;
      .remove-icon {
          margin-right: 5px;
      }
  }
  .divide-line {
      width: 100%;
      position: absolute;
      border-bottom: solid 1px rgba(0,0,0,0.2);
  }
}
</style>
