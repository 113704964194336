export const admin = {
  enterprise: {
    ENTERPRISE_INFO: 'enterprise-info',
    ORGANIZATION_MEMBERS: 'organization-members',
    ENTERPRISE_ROLE_MANAGEMENT: 'enterprise-role-management',
    PROJECT_ROLE_MANAGEMENT: 'project-role-management',
    PROJECT_APPS_MANAGEMENT: 'project-apps-management',
    PROJECT_MANAGEMENT: 'project-management',
    MEMBER_MANAGEMENT: 'member-management'
  },
  project: {
    PROJECT_INFO: 'project-info',
    PROJECT_MEMBERS: 'project-members',
    PROJECT_ROLE: 'project-role',
    PROJECT_APPS_MANAGEMENT: 'apps-management'
  }
}

export const client = {
  WORKBENCH: 'workbench',
  PROJECT_LIST: 'project-list',
  PROJECT_COLLECTION: 'project-collection',
  SHARE_DETAIL: 'share-detail'
}

export const user = {
  USER_CENTER: 'user-center'
}

export const apps = {
  HOME_PAGE: 'home-page',
  PROGRESS_CONTRAST: 'progress-contrast',
  INSPECTION: 'inspection'
}
