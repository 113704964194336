import { $get } from '../http'
import config from '@/config'
export default {
  /**
   * 获取点位分享信息
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getShareDetail: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/eagle/v1.0/hotpoint/share/detail', data)
  }
}
