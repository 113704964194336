import { $delete, $get, $post } from '../http'
import config from '@/config'
export default {
  /**
   * 获取企业应用列表
   * 原接口废弃：/core/org/app/list
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getEnterpriseApps: (data) => {
    return $get(config.BASE_URL + '/core/org/app/list', data)
  },

  /**
   * 获取项目应用列表
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getProjectApps: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/project/user/appAssignment', data)
  },

  /**
   * 获取收藏的应用
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getfavApps: (data) => {
    return $get(config.BASE_URL + '/core/org/app/fav/list', data)
  },

  /**
   * 收藏应用
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  favApp: (data) => {
    return $post(`${config.BASE_URL}/core/org/app/fav`, data)
  },

  /**
   * 删除收藏的应用
   *
   * TODO 废弃
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  deleteFavApp: (data) => {
    return $delete(`${config.BASE_URL}/core/org/app/fav`, data)
  }
}
