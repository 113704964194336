import { admin } from '../../path'
const { project } = admin
const projectChildren = [
  {
    path: 'project',
    name: 'ProjectAdmin',
    component: () => import('@/views/admin/Project.vue'),
    redirect: '/:orgId/:projectId/admin/project/' + project.PROJECT_INFO,
    children: [
      {
        path: project.PROJECT_INFO,
        name: project.PROJECT_INFO,
        component: () => import('@/views/admin/project/ProjectInfo.vue')
      },
      {
        path: project.PROJECT_MEMBERS,
        name: project.PROJECT_MEMBERS,
        component: () => import('@/views/admin/project/ProjectMembers.vue')
      },
      {
        path: project.PROJECT_ROLE,
        name: project.PROJECT_ROLE,
        component: () => import('@/views/admin/project/ProjectRole.vue')
      },
      {
        path: project.PROJECT_APPS_MANAGEMENT,
        name: project.PROJECT_APPS_MANAGEMENT,
        component: () => import('@/views/admin/project/ProjectAppsManagement.vue')
      }
    ]
  }
]

export default projectChildren
