<template>
  <div class="flex flex-a-i-center flex-j-c-center" style="height:100%">
    <!-- 项目设置 -->
    <!-- <a-tooltip placement="topLeft" :title="$t('header.settings')" arrow-point-at-center v-if="showSetting">
      <div class="header-icon" :class="{'header-icon-lg': isBreakpoint}"><IconFont type='icon-shezhi'/></div>
  </a-tooltip> -->
  <!-- <a-tooltip placement="topLeft" :title="$t('header.notify')" arrow-point-at-center> -->
    <!-- <a-badge :count="100" :number-style="{ backgroundColor: '#007AFF' }">
      <div class="header-icon" :class="{'header-icon-lg': isBreakpoint}"><IconFont type='icon-tongzhi'/></div>
    </a-badge> -->
  <!-- </a-tooltip> -->
  <!-- <a-tooltip placement="topLeft" :title="$t('header.language')" arrow-point-at-center> -->
  <a-dropdown :trigger="['click']">
    <div class="header-icon" :class="{'header-icon-lg': isBreakpoint}" @click.prevent><IconFont type='icon-yuyan'/></div>
    <template #overlay>
      <a-menu v-model:selectedKeys="selectedLang" selectable @select="handleLangSelect">
        <a-menu-item key="zhCN">
          简体中文
        </a-menu-item>
        <a-menu-item key="zhTW">
          繁體中文
        </a-menu-item>
        <a-menu-item key="enUS">
          English
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
  <Avatar
    @click="controlDrawer"
    v-if="showAvater"
    :size="28"
    :name="$store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickName || ''"
    :src="$store.state.user && $store.state.user.userInfo ? $store.state.user.userInfo.headAvatar : ''"
    style="font-size: inherit;"
    class="header-icon"
    :class="{'header-icon-lg': isBreakpoint}"
  />
<!--  <a-avatarar-->
<!--    @click="controlDrawer"-->
<!--    v-if="showAvater"-->
<!--    :src="$store.state.user && $store.state.user.userInfo ? $store.state.user.userInfo.headAvatar : ''"-->
<!--    class="header-icon" :class="{'header-icon-lg': isBreakpoint}"-->
<!--    :size="28"-->
<!--    :style="`background-color: ${$store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.headAvatar ? '' : extractColorByName($store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickName)}`">-->
<!--    {{$store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickName?.substr(0, 1)}}-->
<!--  </a-avatarar>-->
  <ArcoDrawer :width="drawerWidth" v-model:visible="visible" :header="false" :footer="false" class="header-drawer">
    <div style="height: 100%; overflow: hidden; display: flex; flex-direction: column">
      <div v-for="(action, uIndex) in userAction" :key="uIndex" class="list-item" @click="action.method">
        <ArcoSpace :style="`color: ${action.color}`">
          <component :is="action.icon"/>
          <span>{{$t(action.name)}}</span>
        </ArcoSpace>
      </div>
      <ArcoDivider margin="4px" />
      <div class="list-item" style="cursor: default; color: var(--color-neutral-6);">
        {{ $t('accountProfiles.changeOrg') }}
      </div>
      <div style="flex: 1; overflow: auto">
        <div v-for="(item) in $store.state.enterprise.enterpriseList" :key="item.orgId" class="list-item org-item" @click="onEnterpriseSelect(item.orgId)">
          <span>
            {{item.orgName}}
          </span>
          <icon-check v-if="Number($route.params.orgId) === item.orgId" style="color: rgb(var(--primary-6))" />
        </div>
      </div>
    </div>
  </ArcoDrawer>

  <!--  个人信息 drawer  -->
  <ArcoDrawer
    v-model:visible="detailDrawerVisible"
    :width="drawerWidth"
    :footer="false"
  >
    <template #title>
      {{ $t('accountProfiles.userInfo') }}
    </template>
    <UserInfo />
  </ArcoDrawer>

  <!-- </a-tooltip> -->
<!--  <a-dropdown :trigger="['click']" overlayClassName="accent-info" v-model:visible="visible">-->
<!--    <a-avatar-->
<!--      v-if="showAvater"-->
<!--      :src="$store.state.user && $store.state.user.userInfo ? $store.state.user.userInfo.headAvatar : ''"-->
<!--      class="header-icon" :class="{'header-icon-lg': isBreakpoint}"-->
<!--      :size="28"-->
<!--      :style="`background-color: ${$store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.headAvatar ? '' : extractColorByName($store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickName)}`">-->
<!--        {{$store.state.user && $store.state.user.userInfo && $store.state.user.userInfo.nickName?.substr(0, 1)}}-->
<!--      </a-avatar>-->
<!--    <template #overlay>-->
<!--      <a-menu :selectedKeys="[Number($route.params.orgId)]" @select="onEnterpriseSelect" selectable v-if="visible">-->
<!--        <div class="enterprise-title">-->
<!--          {{t('header.enterprise')}}-->
<!--        </div>-->
<!--        <a-menu-item-->
<!--          v-for="(item) in $store.state.enterprise.enterpriseList"-->
<!--          :disabled="item.disable"-->
<!--          :key="item.orgId">-->
<!--          <template #icon>-->
<!--            <div>-->
<!--              <div class="img-con">-->
<!--              <img :src="item.orgLogo" class="img-size"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--          <div style="padding:9px 10px;min-width:200px">-->
<!--            {{item.orgName}}-->
<!--          </div>-->
<!--        </a-menu-item>-->
<!--          <div-->
<!--            v-for="(action, uIndex) in userAction"-->
<!--            :key="uIndex"-->
<!--            @click="action.method"-->
<!--            :style="`color: ${action.color}`">-->
<!--              <div class="divide-line"></div>-->
<!--              <div-->
<!--                class="user-action">-->
<!--                {{$t(action.name)}}-->
<!--              </div>-->
<!--          </div>-->
<!--      </a-menu>-->
<!--    </template>-->
<!--  </a-dropdown>-->
  </div>

</template>
<script lang="jsx">
import { computed, defineComponent, onMounted, onUnmounted, reactive, toRefs } from 'vue'
import { COMMON, CLIENT } from '@/store/type'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
// import { client, admin } from '@/router/path'
import { client } from '@/router/path'
import { useI18n } from 'vue-i18n'
import { extractColorByName } from '@/utils/utils'
import {
  Drawer as ArcoDrawer,
  Space as ArcoSpace,
  Divider as ArcoDivider
} from '@arco-design/web-vue'
import {
  IconToLeft,
  IconUser,
  IconCheck
} from '@arco-design/web-vue/es/icon'
import UserInfo from '@/components/user/UserInfo.vue'
import Avatar from '@/components/user/Avatar.vue'
// import Icon from '@ant-design/icons-vue'
// import api from '@/services/api'
export default defineComponent({
  name: 'HeaderAction',
  components: {
    ArcoDrawer,
    ArcoSpace,
    ArcoDivider,
    IconCheck,
    UserInfo,
    Avatar
  },
  props: {
    isBreakpoint: {
      type: Boolean,
      default: false
    },
    showSetting: {
      type: Boolean,
      default: false
    },
    showAvater: {
      type: Boolean,
      default: true
    }
  },
  setup (props, context) {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    // const setAccent = () => {
    //   console.log('setAccent')
    // }

    // const setEnterprise = () => {
    //   console.log('setEnterprise')
    //   router.push({
    //     name: admin.enterprise.MEMBER_MANAGEMENT,
    //     params: { orgId: route.params.orgId }
    //   })
    // }

    const goToUserCenter = () => {
      // router.push({
      //   name: user.USER_CENTER,
      //   params: { orgId: route.params.orgId }
      // })
      state.visible = false
      state.detailDrawerVisible = true
    }

    const loginOut = () => {
      console.log('loginOut')
      store.dispatch('logout')
    }

    let state = reactive({
      selectedLang: null,
      selectedEnterprise: null,
      enterpriseList: [
      ],
      userAction: [
        // {
        //   method: setAccent,
        //   name: '账号设置',
        //   color: 'rgba(0,0,0,0.8)',
        //   icon: 'user-outlined'
        // },
        // 企业设置
        // {
        //   method: setEnterprise,
        //   name: 'enterprise.enterpriseSettings',
        //   color: 'rgba(0,0,0,0.8)',
        //   icon: () => <IconFont type='icon-eagle-eye-building'/>
        // },
        // 个人中心
        {
          method: goToUserCenter,
          name: 'header.userCenter',
          color: 'rgba(0,0,0,0.8)',
          icon: IconUser
        },
        {
          method: loginOut,
          name: 'header.logout',
          color: '#FF5E5E',
          icon: IconToLeft
        }
      ],
      visible: false,
      detailDrawerVisible: false
    })
    onMounted(async () => {
      if (store.state.locale) state.selectedLang = [store.state.locale]
      // getEnterpriseList()

      // 获取并设置企业列表
      if (props.showAvater) {
        store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_LIST)
      }
      // // 获取并设置用户信息
      // store.dispatch('user/' + USERS.SET_USER_INFO)
    })

    const handleLangSelect = ({ key }) => {
      store.dispatch(COMMON.SET_LOCALE, key)
    }

    // 切换企业
    const onEnterpriseSelect = (key) => {
      store.dispatch('enterprise/' + CLIENT.ENTERPRISE.SET_SELECTED, key)
      // if (store.state.enterprise && store.state.enterprise.enterpriseSelected === key) return
      const projectId = route.params.projectId
      router.replace({
        name: route.params.projectId ? client.PROJECT_LIST : route.name,
        params: {
          orgId: key
        }
      }).then(() => {
        if (!projectId) window.location.reload()
      })
    }

    const controlDrawer = () => {
      state.visible = !state.visible
    }

    const drawerWidth = computed(() => {
      console.log(document?.documentElement?.clientWidth)
      if (document?.documentElement?.clientWidth >= 360) {
        return 400
      } else {
        return document?.documentElement?.clientWidth - 40
      }
    })

    // const getEnterpriseList = async () => {
    //   const res = await api.enterprise.getList()
    //   if (res.code === 200) {
    //     const result = res.data
    //     if (result && result.length) {
    //       state.enterpriseList = result
    //       state.selectedEnterprise = [result[0].orgId]
    //     }
    //   }
    // }

    onUnmounted(() => {
      state = null
    })

    return {
      handleLangSelect,
      onEnterpriseSelect,
      ...toRefs(state),
      t,
      extractColorByName,
      controlDrawer,
      drawerWidth
    }
  }
})
</script>
<style lang='less'>
.accent-info {
  .img-con {
      width: 32px;
      height: 32px;
      align-items: center;
      display: flex;
      border: solid 1px rgba(0,0,0,0.05);
      border-radius: 4px;
      justify-content: center;
      background: white;
      img.img-size {
          width: 80%;
      }
  }
  .enterprise-title {
      padding: 8px 12px;
      font-size: 12px;
      color: rgba(0,0,0,0.4);
  }
  .divide-line {
      /* width: 100%; */
      border-bottom: solid 1px rgba(0,0,0,0.2);
      margin: 8px 14px;
  }
  .user-action {
      padding: 10px 24px;
      cursor: pointer;
  }
}
.header-icon {
  margin-left: 24px;
  font-size: 24px;
  cursor: pointer;
  line-height: 0;
}
.header-icon-lg {
  margin-left: 8px;
}
.header-drawer {
  .arco-drawer-body {
    overflow: hidden;
  }
  .list-item {
    padding: 8px 0;
    cursor: pointer;
  }
  .org-item {
    display: flex;
    justify-content: space-between;
  }
}
</style>
