import { createI18n } from 'vue-i18n' // 引入vue-i18n组件
import messages from './index'
const language = (navigator.language ? navigator.language : navigator.userLanguage) || 'zh-CN'
const languageSplit = language.split('-')
const locale = languageSplit && languageSplit.length > 1 ? languageSplit.join('') : 'zhCN'
const i18n = createI18n({
  fallbackLocale: 'zhCN',
  globalInjection: true,
  legacy: false, // you must specify 'legacy: false' option
  locale,
  messages
})

export default i18n
