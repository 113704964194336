import { apps } from '../path'
const appsChildren = [
  {
    path: apps.HOME_PAGE,
    name: apps.HOME_PAGE,
    component: () => import('@/views/client/apps/HomePage.vue')
  },
  {
    path: apps.PROGRESS_CONTRAST,
    name: apps.PROGRESS_CONTRAST,
    component: () => import('@/views/client/apps/ProgressContrast.vue')
  },
  {
    path: apps.INSPECTION,
    name: apps.INSPECTION,
    component: () => import('@/views/client/apps/Inspection.vue')
  }
]
export default appsChildren
