import { client } from '../path'
const clientChildren = [
  // {
  //   path: '/:orgId/' + client.WORKBENCH,
  //   name: client.WORKBENCH,
  //   component: () => import('@/views/client/Workbench.vue')
  // },
  {
    path: '/:orgId/' + client.PROJECT_LIST,
    name: client.PROJECT_LIST,
    component: () => import('@/views/client/project-list/index.vue')
  },
  {
    path: '/:orgId/' + client.PROJECT_COLLECTION,
    name: client.PROJECT_COLLECTION,
    component: () => import('@/views/client/ProjectCollection.vue')
  }
]
export default clientChildren
