import { $get, $put } from '../http'
import config from '@/config'
export default {
  /**
   * 获取个人信息
   *
   * TODO 废弃，目前实用下方的getInfoV2
   * @deprecated
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getInfo: (data) => {
    return $get(config.BASE_URL + '/core/user/info', data)
  },

  /**
   * 更新个人密码
   * 原接口废弃：/core/user/pwd
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  updateUserPass: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/user/pwd', data)
  },

  /**
   * 更新个人信息
   * 原接口废弃：/core/user/info
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  updateUserInfo: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/user/info', data)
  },

  /**
   * 获取个人信息 V2
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getInfoV2: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/user/me', data)
  }
}
