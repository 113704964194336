<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watchEffect, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { COMMON } from '@/store/type'
// import { useI18n } from 'vue-i18n'
import config from '@/config'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import zhTW from 'ant-design-vue/es/locale/zh_TW'
import enUS from 'ant-design-vue/es/locale/en_US'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/en'
import actions from '@/qiankun/action'
// import api from '@/services/api'
// import {
//   // useRouter,
//   useRoute
// } from 'vue-router'

export default defineComponent({
  components: {
  },
  setup () {
    // const router = useRouter()
    const store = useStore()
    // const route = useRoute()
    // const i18n = useI18n()
    const { proxy } = getCurrentInstance()
    document.title = config.WEB_TITLE
    const state = reactive({
      locale: zhCN
    })

    // prefetchApps([
    //   { name: 'eagleApp', entry: '//localhost:8088' }
    // ])

    onMounted(() => {
      let locale = ''
      const localeLocal = localStorage.getItem('locale')
      if (localeLocal) {
        locale = localeLocal
      } else {
        const language = (navigator.language ? navigator.language : navigator.userLanguage) || 'zh-CN'
        const languageSplit = language.split('-')
        locale = languageSplit && languageSplit.length > 1 ? languageSplit.join('') : 'zhCN'
      }

      store.dispatch(COMMON.SET_LOCALE, locale)
      if (document.body.clientWidth) store.dispatch(COMMON.SET_BREAKPOINT, document.body.clientWidth)
      registerWindowResize()
    })

    const registerWindowResize = () => {
      window.onresize = () => {
        if (document.body.clientWidth) store.dispatch(COMMON.SET_BREAKPOINT, document.body.clientWidth)
      }
    }

    watchEffect(() => {
      let dayjsLocale = 'zh-cn'
      switch (store.state.locale) {
        case 'zhCN':
          state.locale = zhCN
          dayjsLocale = 'zh-cn'
          break
        case 'zhTW':
          state.locale = zhTW
          dayjsLocale = 'zh-tw'
          break
        case 'enUS':
          state.locale = enUS
          dayjsLocale = 'en'
          break
        default:
          state.locale = zhCN
          dayjsLocale = 'zh-cn'
          break
      }
      if (store.state.locale) {
        proxy.$i18n.locale = store.state.locale
        localStorage.setItem('locale', store.state.locale)
        dayjs.locale(dayjsLocale)
        // 主应用改变语言时通知微应用
        actions.setGlobalState({
          locale: store.state.locale
        })
      }
    })

    return {
      ...toRefs(state)
    }
  }
})
</script>

<style lang="less">
::-webkit-scrollbar {
  width: 5px !important;
  // height: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px !important;
  background-color: rgba(144,147,153,.3) !important;
}
::-webkit-scrollbar-track {
  // border-radius: 4px;
  // background-color: rgba(144,147,153,.3);
}
.hide-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    // border-radius: 8px;
    &:hover {
      // overflow-y: auto;
      ::-webkit-scrollbar {
        display: block;
      }
    }
}
iframe {
  /* 设置滚动条的样式 */
  ::-webkit-scrollbar {
    width: 6px !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: rgba(144,147,153,.3) !important;
  }
}
#app {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  position: relative;
  overflow: hidden;
  .view-wrap {
    width: 100%;
    height: calc(100vh - 64px);
  }
}

// .panel-wrap {
//   background: #FFFFFF;
//   box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
//   border-radius: 8px;
//   overflow: hidden;
// }
.title-wrap {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  padding: 12px 0;
  line-height: 20px;
}
.loding-con {
  width: 100%;
  height: 100%;
  background: rgba(24, 144, 255, 0.01);
}

.my-layout {
  .admin-enterprise-con .admin-enterprise-layout-sider {
    background: #EDEDED;
  }
  .ant-layout {
    background: #EDEDED;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after {
    border-bottom: 3px solid transparent;
    color: rgba(0, 0, 0, 0.8);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected:hover::after {
    border-bottom: 2px solid #0091FF !important;
}

.ant-menu-item .ant-menu-title-content .tab-text {
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    // text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-item-selected .ant-menu-title-content .tab-text {
    color: #0091FF !important;
}

.clickoff {
  // width: 100%!important;
  // height: 100%!important;
  display: none;
}

.allow-user-select {
  user-select: text;
}
</style>
