import { user } from '../path'
const userChildren = [
  {
    path: user.USER_CENTER,
    name: user.USER_CENTER,
    component: () => import('@/views/user/UserCenter.vue'),
    meta: {
      noOrgId: true
    }
  }
]
export default userChildren
