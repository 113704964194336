import { $delete, $get, $post } from '../http'
import config from '@/config'
export default {
  // 获取个人信息
  getUserInfo: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/user', data)
  },

  // 获取表单列表
  getMomentList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/moment/list', data)
  },

  // 获取表单详情
  getFormDetail: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/form', data)
  },

  // 获取统计数据
  getChartData: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/hashtag/statistics', data)
  },

  // 获取标签列表
  getTagList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/hashtag/list', data)
  },

  // 保存标签
  addTag: (data) => {
    return $post(config.PHOTO_APP_PATH + '/hashtag', data)
  },

  /**
   * 动态圈评论
   * 原接口废弃：/moment/comment
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  comment: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/meta/v1.0/moment/comment', data)
  },

  /**
   * 获取动态圈评论列表
   * 原接口废弃：/moment/comment/list
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getCommentList: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/moment/comment/list', data)
  },

  /**
   * 获取OSS上传链接
   * 原接口废弃：/oss/common/auth
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getOssUrl: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/oss/common/auth', data)
  },

  /**
   * 获取水印相机OSS上传链接
   * 原接口废弃：/oss/watermark/auth
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getWatermarkOssUrl: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/oss/watermark/auth', data)
  },

  // 上传表单
  uploadForm: (data) => {
    return $post(config.PHOTO_APP_PATH + '/form', data)
  },

  /**
   * 查询消息通知列表
   * 原接口废弃：/notify/list
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getMessageList: (data = {}) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/notify/list', data)
  },

  /**
   * 查询未读消息总数
   * 原接口废弃：/notify/qty
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getMessageNum: (data = {}) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/notify/qty', data)
  },

  // 点赞动态
  likeMoment: (data = {}) => {
    return $post(config.PHOTO_APP_PATH + '/moment/like', data)
  },

  // 获取项目列表
  getProjectList: (data = {}) => {
    return $get(config.PHOTO_APP_PATH + '/project/list', data)
  },

  // 发布动态
  publishMoment: (data = {}) => {
    return $post(config.PHOTO_APP_PATH + '/moment', data)
  },

  /**
   * 获取动态圈详情
   * 原接口废弃：/moment
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getMomentDetail: (data = {}) => {
    return $get(config.OCEAN_API_GATEWAY + '/meta/v1.0/moment', data)
  },

  // 删除动态
  deleteMoment: (data = {}) => {
    return $delete(config.PHOTO_APP_PATH + '/moment', data)
  }
}
