import { admin } from '../../path'
const { enterprise } = admin
const enterpriseChildren = [
  {
    path: 'enterprise',
    name: 'EnterpriseAdmin',
    component: () => import('@/views/admin/Enterprise.vue'),
    redirect: '/:orgId/admin/enterprise/' + enterprise.ENTERPRISE_INFO,
    children: [
      {
        path: enterprise.ENTERPRISE_INFO,
        name: enterprise.ENTERPRISE_INFO,
        component: () => import('@/views/admin/enterprise/base/EnterpriseInfo.vue')
      },
      {
        path: enterprise.ORGANIZATION_MEMBERS,
        name: enterprise.ORGANIZATION_MEMBERS,
        component: () => import('@/views/admin/enterprise/member/OrganizationMembers.vue')
      },
      {
        path: enterprise.ENTERPRISE_ROLE_MANAGEMENT,
        name: enterprise.ENTERPRISE_ROLE_MANAGEMENT,
        component: () => import('@/views/admin/enterprise/member/EnterpriseRole.vue')
      },
      {
        path: enterprise.PROJECT_ROLE_MANAGEMENT,
        name: enterprise.PROJECT_ROLE_MANAGEMENT,
        component: () => import('@/views/admin/enterprise/member/ProjectRole.vue')
      },
      {
        path: enterprise.PROJECT_APPS_MANAGEMENT,
        name: enterprise.PROJECT_APPS_MANAGEMENT,
        component: () => import('@/views/admin/enterprise/app/ProjectApp.vue')
      },
      {
        path: enterprise.PROJECT_MANAGEMENT,
        name: enterprise.PROJECT_MANAGEMENT,
        component: () => import('@/views/admin/enterprise/project/ProjectManagement.vue')
      },
      {
        path: enterprise.MEMBER_MANAGEMENT,
        name: enterprise.MEMBER_MANAGEMENT,
        component: () => import('@/views/admin/enterprise/member/MemberManagement.vue')
      }
    ]
  }
]

export default enterpriseChildren
