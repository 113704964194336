import { $get, $post, $put } from './http'
import enterprise from './module/enterprise'
import project from './module/project'
import user from './module/user'
import app from './module/app'
import invite from './module/invite'
import moment from './module/moment'
import share from './module/share'
import config from '@/config'

console.log(process.env)
const api = {
  getConfig: (data = {}) => {
    return $get(`/config/config.json?v=${new Date().getTime()}`, data)
  },

  /**
   * 账号密码登录
   * 原接口废弃：/core/auth/password-login
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  login: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/auth/pwd/sign-in', data)
  },

  /**
   * 手机验证码登录
   * 原接口废弃：/core/auth/sms-login
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  loginByPhone: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/auth/sms/sign-in', data)
  },

  /**
   * 注册获取验证码
   * 原接口废弃：/core/user/register/sms-code
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getCodeWhenSignin: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/register', data)
  },

  /**
   * 登录获取验证码
   * 原接口废弃：/core/auth/sms-code
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getCodeWhenLogin: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/login', data)
  },

  /**
   * 获取重置密码验证码
   * 原接口废弃：/core/v1.0/sms/reset-password
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getVerifyCode: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/reset-password', data)
  },

  /**
   * 获取通过邮箱重置密码验证码
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getVerifyCodeWithEmail: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/email/reset-password', data)
  },

  /**
   * 重置密码（短信）
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  resetPassword: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/user/reset-password/sms', data)
  },

  /**
   * 重置密码（邮箱）
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  resetPasswordWithEmail: (data) => {
    return $put(config.OCEAN_API_GATEWAY + '/core/v1.0/user/reset-password/email\n', data)
  },

  /**
   * 获取绑定手机验证码
   * 原接口废弃：/core/v1.0/user/bind/phone
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getCodeWhenBindPhone: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/sms/bind-phone', data)
  },

  /**
   * 绑定手机
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  bindPhone: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/user/bind/phone', data)
  },

  /**
   * 获取绑定邮箱验证码
   *
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  getCodeWhenBindEmail: (data) => {
    return $get(config.OCEAN_API_GATEWAY + '/core/v1.0/code/email/bind-email', data)
  },

  /**
   * 通过邮箱验证码绑定邮箱
   * @param data
   * @returns {Promise | Promise<unknown>}
   */
  bindEmail: (data) => {
    return $post(config.OCEAN_API_GATEWAY + '/core/v1.0/user/bind/email', data)
  },
  enterprise,
  user,
  app,
  project,
  invite,
  moment,
  share
}

export default api
