<template>
  <a-modal
    class="share-dialog-con"
    :title="$t('invite.inviteTitle')"
    v-bind="$attrs"
    :footer="null"
  >
    <div class="divide-line"></div>
    <a-select
      v-model:value="selectedMember"
      show-search
      :placeholder="$t('invite.searchTitle')"
      style="width: 100%"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      :options="shareData"
      @search="handleSearch"
      @change="handleChange"
      size="large"
    >
      <template #suffixIcon>
        <!-- <user-outlined type="user" /> -->
        <SearchOutlined />
      </template>
    </a-select>
    <div class="invite-tips">
      {{$t('invite.inviteTip')}}
    </div>
    <div class="flex flex-a-i-center share-link-con">
      <div style="padding: 10px 16px;">
        <div class="">{{ shareLink }}</div>
      </div>
      <div class="copy-button">
        {{$t('invite.copyLink')}}
      </div>
    </div>
    <div class="footer-wrap">
      {{$t('invite.effectiveDate') + ':' + toDate}}
    </div>
  </a-modal>
</template>

<script >
import { defineComponent, reactive, toRefs } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import { message } from 'ant-design-vue'
import { copyLink } from '@/utils/utils'
export default defineComponent({
  props: {
    shareLink: {
      type: String,
      default: ''
    },
    toDate: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchOutlined
  },
  setup (props, context) {
    const { t } = useI18n()

    const state = reactive({
      code: '',
      shareData: [],
      selectedMember: null
    })

    const myCopyLink = (shareLink) => {
      copyLink(shareLink, () => {
        message.success(t('invite.copySuccessed'))
      })
    }

    const handleSearch = (e) => {
      console.log(e)
    }

    const handleChange = (e) => {
      console.log(e)
    }

    return {
      ...toRefs(state),
      myCopyLink,
      handleSearch,
      handleChange
    }
  }
})
</script>

<style lang="less">
.share-dialog-con {
  border-radius: 4px;
  overflow: hidden;
  top: calc(50% - 210px);
  .ant-modal-header {
    padding: 16px 16px 10px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: none;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 16px;
  }
  .ant-modal-body {
    font-size: 16px;
    padding: 0 16px;
  }
  .ant-modal-footer {
    padding: 16px;
    border-top: none;
  }
}
</style>

<style lang="less" scoped>
  .share-dialog-con {
    .share-tips {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 14px;
      margin-bottom: 16px;
    }
    .share-link-con {
      background: #f4f7fd;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 400;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
    }
    .share-link-title {
      margin-right: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;
    }
    .copy-button {
      /* height: 100%; */
      padding: 10px 16px;
      background: #008FFC;
      color: white;
      cursor: pointer;
    }
    .share-link-copy {
      width: 110px;
      height: 30px;
      background: #008FFC;
      color: #fff;
      border-radius: 4px;
    }
    .divide-line {
      width: 100%;
      border-bottom: solid 1px rgba(0,0,0,0.2);
      margin: 6px 0 16px 0;
    }
    .invite-tips {
      margin: 24px 0 12px;
      /* font-size: 16px; */
      font-weight: 500;
      color: rgba(0,0,0,0.8);
    }
    .footer-wrap {
      color: rgba(0,0,0,0.6);
      padding-bottom: 16px;
      font-size: 14px;
    }
  }
  .grey-font {
    color: rgba(0, 0, 0, 0.6)
  }
</style>
