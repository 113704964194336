import axios from 'axios'
// import qs from 'qs'
import {
  requstConfig,
  requstError,
  responseSuccess,
  responseError
} from './interceptor'

// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// axios.defaults.withCredentials = true
axios.defaults.timeout = 180000

axios.interceptors.request.use(requstConfig, requstError)
axios.interceptors.response.use(responseSuccess, responseError)

const $http = (p, method = 'GET') => {
  const { url, data, params, headers = {} } = p
  var option = {
    url,
    data,
    method,
    headers,
    params
  }
  if (method === 'GET' || method === 'DELETE') {
    option.params = params
    delete option.data
  }
  return new Promise((resolve, reject) => {
    axios.request(option)
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.response)
      })
  })
}

const $get = (url, data = {}) => {
  const params = { url, params: data }
  return $http(params)
}

const $post = (url, data = {}, params = {}, headers = {}) => {
  const myParams = {
    url,
    data,
    headers,
    params
  }
  return $http(myParams, 'POST')
}

const $delete = (url, data = {}, params = {}) => {
  const myParams = { url, params: data }
  return $http(myParams, 'DELETE')
}

const $put = (url, data = {}, params = {}, headers = {}) => {
  const myParams = {
    url,
    data,
    headers,
    params
  }
  return $http(myParams, 'PUT')
}

export {
  $http,
  $get,
  $post,
  $delete,
  $put
}
