import router from '@/router/index.js'
import store from '../store'
import { message } from 'ant-design-vue'
// import { nextTick } from 'vue'
// import { Message } from 'element-ui'
// import router from '../router'

message.config({
  maxCount: 1
})
const requstConfig = config => {
  let local
  switch (store.state.locale) {
    case 'zhCN':
      local = 'zh-cn'
      break
    case 'zhTW':
      local = 'zh-tw'
      break
    case 'enUS':
      local = 'en'
      break
    default:
      local = 'zh-cn'
      break
  }
  config.headers = Object.assign(config.headers || {}, { Authorization: store.getters.fullToken, 'Accept-Language': local })
  return config
}

const requstError = error => {
  return Promise.error(error)
}

const responseSuccess = (res) => {
  // 对响应数据做点什么
  const data = res.data
  const config = res.config
  if (data) {
    const code = data.code
    switch (code) {
      case 401:
        // 返回 401 清除token信息并跳转到登录页面
        toLogin()
        break
      case 403:
        // 返回 403 提示没权限
        message.warning('权限不足，如有疑问请联系管理员')
        break
      default:
        if (code !== 200 && config.method && config.method.toLowerCase() !== 'get') {
          if (data.msgDetail) message.error(data.msgDetail)
          else if (data.detail) message.error(data.detail)
          else if (data.msg) message.error(data.msg)
        }
        break
    }
  }
  return res
}

const responseError = (error) => {
  const res = error.response
  // 对响应错误做点什么
  if (res && res.status) {
    switch (error.response.status) {
      case 401:
        // 返回 401 清除token信息并跳转到登录页面
        toLogin()
    }
  } else {
    message.error(error + '')
  }
  return Promise.reject(error)
}

const toLogin = () => {
  setTimeout(() => {
    store.dispatch('logout')
    console.log(router.currentRoute)
    // if (!router.currentRoute.meta || !router.currentRoute.meta.noAuthentication) {
    //   router.replace({
    //     name: 'Login',
    //     query: { redirect: router.currentRoute.fullPath }
    //   })
    // }
  }, 0)
}

export {
  requstConfig,
  requstError,
  responseSuccess,
  responseError
}
