<template>
  <ArcoModal
    :title="$t('accountProfiles.bindPhone')"
    title-align="start"
    v-model:visible="visible"
    :on-before-ok="submit"
    @before-close="close"
    :ok-text="$t('accountProfiles.confirmBind')"
    :cancel-text="$t('accountProfiles.cancel')">
    <ArcoForm
      :model="form"
      ref="formRef"
      label-align="left"
      :rules="rules">
      <ArcoFormItem
        hide-asterisk
        field="phone"
        :label="$t('accountProfiles.phone')"
        validate-trigger="input"
        :validate-status="phoneValidateStatus"
        :help="phoneValidateTips">
        <ArcoInput
          v-model="form.phone"
          :show-word-limit="true"
          :placeholder="$t('accountProfiles.phoneTips')"
          allow-clear/>
      </ArcoFormItem>
      <ArcoFormItem
        hide-asterisk
        field="verificationCode"
        :label="$t('accountProfiles.verifyCode')">
        <ArcoRow style="width: 100%;" :gutter="8">
          <ArcoCol flex="auto">
            <ArcoInput
              style="flex: 1;"
              v-model="form.verificationCode"
              :placeholder="$t('accountProfiles.verifyCode')" />
          </ArcoCol>
          <ArcoCol flex="none">
            <ArcoButton
              ghost
              type="primary"
              style="width: 100%;"
              @click="sendCode"
              v-if="canGetCode">
              {{ $t('accountProfiles.sendVerifyCode') }}
            </ArcoButton>
            <ArcoButton
              v-else
              ghost
              type="primary"
              style="width: 100%;">
              {{timeLeft}} {{$t('forgetPassword.second')}}
            </ArcoButton>
          </ArcoCol>
        </ArcoRow>
      </ArcoFormItem>
    </ArcoForm>
  </ArcoModal>
</template>

<script setup>
import { reactive, ref, defineExpose, defineEmits } from 'vue'
import {
  Modal as ArcoModal,
  Form as ArcoForm,
  FormItem as ArcoFormItem,
  Input as ArcoInput,
  Row as ArcoRow,
  Col as ArcoCol,
  Button as ArcoButton,
  Message
} from '@arco-design/web-vue'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'

const { t } = useI18n()
const formRef = ref(null)

const emit = defineEmits([
  'success'
])

const phoneValidate = async (value, callback) => {
  if (value === '') {
    // return Promise.reject(new Error(t('forgetPassword.phoneTips')))
    callback()
  } else if (!/^1\d{10}$/.test(value)) { // 是否满足「以1开头的11位数字」格式
    // return Promise.reject(new Error(t('forgetPassword.phoneErrorTips')))
    callback(t('accountProfiles.bindPhoneTips'))
  } else {
    return Promise.resolve()
  }
}

const codeValidate = async (value, callback) => {
  if (value === '' || !value) {
    callback(t('forgetPassword.verificationTips'))
    // return Promise.reject(new Error(t('forgetPassword.verificationTips')))
  } else {
    return Promise.resolve()
  }
}
const canGetCode = ref(true)
const timeLeft = ref(0)
const rules = ref({
  phone: [{ validator: phoneValidate, trigger: 'blur' }],
  verificationCode: [{ validator: codeValidate, trigger: 'blur' }]
})
const visible = ref(false)
const form = reactive({
  phone: '',
  verificationCode: ''
})

const show = () => {
  visible.value = true
}

// 手机校验状态，用于校验手机是否已使用
const phoneValidateStatus = ref()
// 手机校验失败提示语
const phoneValidateTips = ref()

let intervalId

/**
 * 开启计时器
 */
const startTimer = () => {
  if (intervalId) {
    clearInterval(intervalId)
    intervalId = undefined
  }
  canGetCode.value = false
  timeLeft.value = 60
  intervalId = setInterval(() => {
    timeLeft.value -= 1
    if (timeLeft.value <= 0) {
      canGetCode.value = true
      clearInterval(intervalId)
      intervalId = undefined
    }
  }, 1000)
}

/**
 * 获取绑定手机验证码
 * @returns {Promise<void>}
 */
const sendCode = async () => {
  if (!/^1\d{10}$/.test(form.phone)) {
    return
  }
  const res = await api.getCodeWhenBindPhone({ areaCode: '+86', number: form.phone })
  if (res && res.code === 200) {
    phoneValidateStatus.value = undefined
    phoneValidateTips.value = undefined
    Message.success(t('forgetPassword.getVerifyCodeSuccessTips'))
    startTimer()
  } else {
    if (res.detail) {
      phoneValidateStatus.value = 'error'
      phoneValidateTips.value = res.detail
      Message.error(res.detail)
    }
  }
}

const close = () => {
  formRef.value.resetFields()
}

/**
 * 通过手机验证码绑定手机
 * @returns {Promise<void>}
 */
const submit = async () => {
  try {
    const res = await formRef.value?.validate()
    if (res) {
      // 校验失败，不关闭Modal
      return false
    }
    const response = await api.bindPhone({ areaCode: '+86', number: form.phone, code: form.verificationCode })
    if (response && response.code === 200) {
      Message.success(t('accountProfiles.bindPhoneSuccess'))
      emit('success')
      return true
    } else {
      if (response.detail) {
        // Message.error(response.detail)
        return false
      }
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

defineExpose({
  show
})

</script>

<style scoped lang="less">

</style>
