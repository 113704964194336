<template>
  <ArcoSpace direction="vertical" fill>
    <template #split>
      <ArcoDivider margin="10px" />
    </template>

    <!--   姓名   -->
    <ArcoRow align="center" :gutter="10">
      <ArcoCol flex="none">
        <Avatar
          :size="64"
          :name="userInfo.nickName"
          :src="userInfo.headAvatar"
        />
      </ArcoCol>
      <ArcoCol flex="auto">
        <ArcoSpace direction="vertical">
          <span class="label-name"> {{ $t('accountProfiles.name') }} </span>
          <div class="default-title-none"> {{ userInfo.nickName }} </div>
        </ArcoSpace>
      </ArcoCol>

      <!--   编辑 按钮   -->
      <ArcoCol flex="none">
        <ArcoButton type="text" @click="editInfo">
          <template #icon>
            <icon-edit />
          </template>
          {{ $t('accountProfiles.edit') }}
        </ArcoButton>
      </ArcoCol>
    </ArcoRow>

    <!--   登录密码   -->
    <ArcoRow align="center" :gutter="10">
      <ArcoCol flex="auto">
        <ArcoSpace direction="vertical">
          <span class="default-label-title">
            {{ $t('accountProfiles.pass') }}
          </span>
          <div class="default-label-value">
            {{ $t('accountProfiles.setted') }}
          </div>
        </ArcoSpace>
      </ArcoCol>

      <!--   重置密码 按钮   -->
      <ArcoCol flex="none">
        <ArcoButton type="text" @click="editPass">
          <template #icon>
            <icon-refresh />
          </template>
          {{ $t('accountProfiles.editPass') }}
        </ArcoButton>
      </ArcoCol>
    </ArcoRow>

    <!--   绑定邮箱   -->
    <ArcoRow align="center" :gutter="10">
      <ArcoCol flex="auto">
        <ArcoSpace direction="vertical">
          <span class="default-label-title">
            {{ $t('accountProfiles.bindEmail') }}
          </span>
          <div class="default-label-value"> {{ userInfo.email || $t('accountProfiles.unbind') }} </div>
        </ArcoSpace>
      </ArcoCol>

      <!--   更换邮箱 按钮   -->
      <ArcoCol flex="none">
        <ArcoButton type="text" @click="bindEmail">
          <template #icon>
            <icon-plus />
          </template>
          {{ $t('accountProfiles.bindEmail') }}
        </ArcoButton>
      </ArcoCol>
    </ArcoRow>

    <!--   绑定手机   -->
    <ArcoRow align="center" :gutter="10" v-if="!disableSMSLogin">
      <ArcoCol flex="auto">
        <ArcoSpace direction="vertical">
          <span class="default-label-title">
            {{ $t('accountProfiles.bindPhone') }}
          </span>
          <div class="default-label-value"> {{ userInfo.phone || $t('accountProfiles.unbind') }} </div>
        </ArcoSpace>
      </ArcoCol>

      <!--   绑定手机 按钮   -->
      <ArcoCol flex="none">
        <ArcoButton type="text" @click="bindPhone">
          <template #icon>
            <icon-plus />
          </template>
          {{ $t('accountProfiles.bindPhone') }}
        </ArcoButton>
      </ArcoCol>
    </ArcoRow>
  </ArcoSpace>
  <ArcoDivider margin="10px" />
  <EditInfoModal ref="editInfoRef" />
  <BindEmailModal ref="bindEmailRef" @success="updateInfoSuccess"/>
  <BindPhoneModal ref="bindPhoneRef" @success="updateInfoSuccess"/>
  <EditPasswordModal ref="editPassRef" />
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import {
  Space as ArcoSpace,
  Divider as ArcoDivider,
  Row as ArcoRow,
  Col as ArcoCol,
  Button as ArcoButton
} from '@arco-design/web-vue'
import Avatar from './Avatar.vue'
import EditInfoModal from '@/components/user/arcoModal/EditInfoModal.vue'
import BindEmailModal from '@/components/user/arcoModal/BindEmailModal.vue'
import BindPhoneModal from '@/components/user/arcoModal/BindPhoneModal.vue'
import EditPasswordModal from '@/components/user/arcoModal/EditPasswordModal.vue'
import {
  IconRefresh,
  IconEdit,
  IconPlus
} from '@arco-design/web-vue/es/icon'
import { USERS } from '@/store/type'
import config from '@/config'
export default defineComponent({
  name: 'UserInfo',
  components: {
    ArcoSpace,
    ArcoDivider,
    ArcoRow,
    ArcoCol,
    ArcoButton,
    Avatar,
    IconRefresh,
    IconPlus,
    IconEdit,
    EditInfoModal,
    BindEmailModal,
    BindPhoneModal,
    EditPasswordModal
  },
  setup () {
    const store = useStore()
    const editInfoRef = ref()
    const bindEmailRef = ref()
    const bindPhoneRef = ref()
    const editPassRef = ref()

    const userInfo = computed(() => (store.state.user && store.state.user.userInfo) || {})
    // 是否禁用短信相关逻辑(短信登录, 忘记密码)
    const disableSMSLogin = computed(() => config.DISABLE_SMS_LOGIN === 'true')

    const editInfo = () => {
      editInfoRef.value.show(userInfo.value.nickName, userInfo.value.headAvatar)
    }

    const bindEmail = () => {
      bindEmailRef.value.show()
    }

    const bindPhone = () => {
      bindPhoneRef.value.show()
    }

    const editPass = () => {
      editPassRef.value.show()
    }

    const updateInfoSuccess = () => {
      store.dispatch('user/' + USERS.SET_USER_INFO)
    }
    return {
      userInfo,
      editInfo,
      editInfoRef,
      bindEmailRef,
      bindEmail,
      bindPhone,
      bindPhoneRef,
      editPass,
      editPassRef,
      updateInfoSuccess,
      disableSMSLogin
    }
  }
})
</script>

<style scoped lang="less">
.label-name {
  color: var(--color-neutral-6);
  font-size: 12px;
}
</style>
