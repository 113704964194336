export default {
  header: {
    settings: 'Settings',
    notify: 'Notifications',
    language: 'Languages',
    workbench: 'Workbench',
    projectList: 'Projects List',
    projectCollection: 'Projects Favorites',
    enterprise: 'Organization',
    logout: 'Logout',
    message: 'Messages',
    projectSetting: 'Project Settings',
    userCenter: 'Personal Information'
  },
  enterprise: {
    enterpriseSettings: 'Organization Settings',
    menu: {
      basicInformation: 'Basic Information',
      enterpriseInformation: 'Organization Information',
      user: 'Personnel',
      organizationAndMembers: 'Organization & Members',
      enterpriseRoleManagement: 'Organization Role Management',
      projectRoleManagement: 'Project Role Management',
      application: 'Applications',
      projectApplicationManagement: 'Project Application Management',
      project: 'Projects',
      projectManagement: 'Project Management',
      projectCapacity: 'Project Capacity',
      personnelQuota: 'Personnel capacity',
      memberManagement: 'Personnel Management'
    },
    memberManagement: {
      title: 'Personnel Management',
      invitePeople: 'Invite Personnel',
      enterName: 'Please enter a nickname to search...',
      project: 'Programs',
      role: 'Organization Role',
      state: 'Status',
      all: 'All',
      userId: 'User Id',
      username: 'Name',
      email: 'Email',
      action: 'Operation',
      enabled: 'Enable',
      disabled: 'Disable'
    }
  },
  project: {
    projectSettings: 'Project Settings',
    searchProject: 'Search Project',
    menu: {
      projectInfo: 'Project Information',
      projectMembers: 'Project Personnel',
      projectRole: 'Project Roles',
      projectAppsManagement: 'Project Application Management'
    },
    projectList: {
      members: '{count} members',
      started: 'Started {days} days',
      lastUpdated: 'Last updated {time}'
    },
    favAppSuccess: 'Favorite Application Successfully',
    deleteAppSuccess: 'Unfavorite Application Successfully',
    projectMembers: {
      title: 'Project Staff',
      invitePeople: 'Invite Personnel',
      enterName: 'Please enter a nickname to search...',
      project: 'Project',
      state: 'Status',
      all: 'All',
      userId: 'User Id',
      username: 'Name',
      email: 'Email',
      role: 'Project Role',
      action: 'Action',
      enabled: 'Enable',
      disabled: 'Disable'
    }
  },
  admin: {
    invite: {
      invitePerson: 'Invite Personnel',
      inviteByEmail: 'Invite by email',
      inviteByLink: 'Invite by link',
      invite: 'Invite',
      copyLink: 'Copy Link',
      enterpriseRole: 'Organization Role',
      enterRole: 'Please select role',
      enterProject: 'Please select project',
      project: 'Project',
      projectRole: 'Project Role',
      inviteEmail: 'Invite personnel email',
      inviteEmailTips: "Please split by the format [email address] (split by multi-line carriage return), e.g. kason123{'@'}jarvisbim.com"
    }
  },
  accountProfiles: {
    userInfo: 'Personal Information',
    changeOrg: 'Switch Businesses ',
    name: 'Name',
    passSafe: 'Security & Login',
    pass: 'Password',
    edit: 'Edit',
    sure: 'OK',
    changeName: 'Change name',
    enterName: 'Please enter your name',
    editName: 'Change name successfully',
    save: 'Save',
    editPass: 'Change password',
    oldPass: 'Old password',
    newPassWithRule: 'New password (A password that is between 8 and 20 characters long and contains letters, numbers, and symbols)',
    newPass: 'New Password',
    enterNewPassAgain: 'Enter new password again',
    enterOldPass: 'Please enter old password',
    enterNewPass: 'Please enter new password',
    passRule: 'Please enter a password that is between 8 and 20 characters long and contains letters, numbers, and symbols',
    passDifferent: 'Inconsistency between two password entries',
    // 绑定手机
    bindPhone: 'Bind phone number',
    phone: 'Phone Number',
    unbind: 'Not bind',
    bind: 'Binding',
    phoneTips: 'Please enter your phone number',
    bindPhoneTips: 'Please enter the correct phone number ',
    verifyCode: 'Verification Code',
    sendVerifyCode: 'Send verification code',
    bindPhoneSuccess: 'Bind cell phone successfully',
    // 绑定邮箱
    email: 'Email',
    emailTips: 'Please enter email',
    bindEmailTips: 'Please enter email',
    bindEmailSuccess: 'Binding email successfully',
    emailErrorTips: 'Please enter a valid email address',
    editPassSuccess: 'Password changed successfully',
    bindEmail: 'Bind mailbox ',
    confirmBind: 'Confirm binding ',
    emailAddress: 'Email address ',
    emailInputTips: 'Enter the email address to be bound ',
    // New personal information
    editInfoTitle: 'Edit Personal information ',
    editAvatar: 'Modify Avatar ',
    editAvatarTip: 'In order to ensure the display effect, it is recommended to use 1:1 ratio, jpg format, png image, image size does not exceed 4MB',
    avatarError: 'Current format does not support upload ',
    changeSuccess: 'Modified successfully ',
    editNickname: 'Change name ',
    editNicknameTip: 'Enter a name ',
    sizeLimit: 'Image size over 4MB',
    // New password
    confirmEdit: 'Confirm changes ',
    oldPsw: 'Old password ',
    enterOldPsw: 'Enter old password ',
    newPsw: 'New password ',
    enterPsw: 'Enter password ',
    confirmPsw: 'Confirm password ',
    pswRule: 'Must be between 8 and 20 characters in length and contain English letters, numbers, symbols ',
    setted: 'Set',
    cancel: 'Cancel'
  },
  login: {
    login: 'Login',
    userInputTips: 'Enter your phone number or email address',
    pswInputTips: 'Please enter your password',
    loginTitle: 'Login JARVIS Eagle Eye',
    jllTitle: 'Welcome to JARVIS',
    forgetPsw: 'Forgot password',
    userNullError: 'Please enter your phone number or email address',
    pswNullError: 'Please enter your password',
    smsLogin: 'SMS Login',
    accountLogin: 'Account Login',
    getCode: 'Get verification code',
    inputCode: 'Please enter verification code',
    enterAccount: 'Please enter email or account name',
    mobilePhoneNumber: 'Please enter your phone number',
    sendSuccess: 'Send verification code successfully',
    phoneErrorRule: 'The format of phone number is not correct',
    codeErrorRule: 'Verification code should be 6 digits'
  },
  forgetPassword: {
    formTitle: 'Forgot password',
    phone: 'Phone Number',
    email: 'Email',
    verificationCode: 'Verification Code',
    getCode: 'Get verification code',
    enterPassword: 'Enter new password',
    confirmPassword: 'Confirm password',
    resetPassword: 'Reset password',
    phoneErrorTips: 'Please enter 11-digit phone number',
    getVerifyCodeSuccessTips: 'Get verification code successfully',
    confirmPasswordErrorTips: 'Password is not the same',
    resetPasswordSuccessTips: 'Reset password successfully',
    backToLogin: 'Return to login page',
    emailTips: 'Enter your email address',
    emailNullError: 'Please enter your email address',
    emailRuleError: 'Please enter the correct email format',
    phoneTips: 'Enter your cell phone number',
    verificationTips: 'Enter verification code',
    passwordTips: 'Enter password',
    passwordRuleError: 'A password that is between 8 and 20 characters long and contains letters, numbers, and symbols',
    second: 'Seconds',
    phoneTab: 'Verify via phone',
    emailTab: 'Verify via email'
  },
  signIn: {
    formTitle: 'Register personal information',
    email: 'Email',
    name: 'Name',
    enterPassword: 'Enter new password',
    confirmPassword: 'Re-enter new password',
    signIn: 'Submit',
    backToLogin: 'Already have an account? Go to login',
    emailTips: 'Enter your email address',
    nameTips: 'Enter your name',
    passwordTips: 'Enter password',
    confirmPasswordTips: 'Confirm password',
    emailNullError: 'Please enter your email address',
    userNullError: 'Enter your name',
    passwordNullError: 'Enter new password',
    checkPassNullError: 'Please enter the new password again',
    checkPassDifferentError: 'Inconsistent with new password',
    emailRuleError: 'Please enter the correct email format',
    passwordRuleError: 'A password that is between 8 and 20 characters long and contains letters, numbers, and symbols',
    withPhone: 'Phone registration',
    phone: 'Phone number',
    phoneNullError: 'Please enter your phone number',
    verificationCode: 'Verification Code',
    verificationTips: 'Please enter the verification code',
    withEmail: 'Email Registration',
    phoneRuleError: 'The format of phone number is not correct',
    codeRoleError: 'Verification code should be 6 digits',
    sendSuccess: 'Verification code sent',
    signInSuccess: 'Registration successful',
    appQR: 'Android Download QR Code',
    iosQR: 'iOS Download QR Code',
    downloadTip: 'Scan the QR code to download APP login and unlock more functions'
  },
  invite: {
    inviteTitle: 'Invite to join the program',
    searchTitle: 'Search for Organization members, or enter your phone number or email address to invite them directly',
    inviteTip: 'Invite more people to join the program',
    copyLink: 'Copy link',
    effectiveDate: 'Invitation link is valid until',
    copySuccessed: 'Copy successfully',
    selectOrg: 'Select Organization',
    sure: 'Confirm',
    userTitle: 'Project Invitation',
    userContent: 'Invite you to join',
    userConfirm: 'Join project'
  },
  apps: {
    mainPage: 'Home',
    projectMember: 'Project Members',
    invite: 'Invite',
    allMember: 'All Members',
    addMember: 'Add Member',
    memberSetting: 'Member Settings',
    removeMember: 'Remove Members'
  },
  workbench: {
    tasks: 'Pending Tasks',
    application: 'Initiated by me',
    noFavoriteApps: 'No favorite application',
    toProjectTips: 'Enter the project, move the mouse over the top app name and click the Star icon to bookmark the application, click the Star icon again to cancel the bookmark and enter the project via {projectList}',
    projectList: 'Projects List',
    cc: 'Copied to me',
    iInitiated: 'Initiated by me',
    iApproved: 'Approved by me',
    haveDone: 'Done by me',
    allTasks: 'All pending',
    expired: 'Expired',
    expiring: 'Expiring',
    nudge: 'Reminder',
    processingWorkflow: 'Process the tasks in time can greatly improve your work efficiency',
    favoriteApps: 'Favorite Applications',
    unread: 'Pending review',
    underReview: 'Unread',
    workCard: 'Work cards',
    iSubmitted: 'Submitted by me',
    table: {
      title: 'Title',
      flowStatus: 'Process Status',
      updateTime: 'Update time',
      initiator: 'Initiator'
    }
  },
  moment: {
    publishMoment: 'Post to Eagle Eye Meta',
    publish: 'Post',
    cancel: 'Cancel',
    selectedProject: 'Select item to post',
    description: 'Description',
    enter: 'Enter',
    select: 'Select',
    floor: 'Project/Area/Floor',
    type: 'Work Type',
    principals: 'Person in Charge',
    other: 'Other',
    pleaseText: 'Please',
    publishSuccess: 'Post successfully',
    publishComment: 'Posting comment...',
    send: 'Send',
    comment: 'Comment',
    replyComment: 'Reply to comment',
    newReply: 'Add reply...',
    reply: 'Reply',
    photoDetail: 'Image Details',
    typeCount: 'Type Statistics',
    userCount: 'User statistics',
    tag: 'Tags',
    back: 'Return',
    allProject: 'All items',
    mine: 'My',
    commented: 'Commented',
    replied: 'Replied',
    liked: 'Liked',
    moment: 'Meta',
    detail: 'Details',
    loadMore: 'Loading more',
    message: 'Messages',
    all: 'All',
    like: 'Likes',
    eagleEye: 'Eagle Eye Meta',
    ernterContent: 'Please enter content',
    goTop: 'Top',
    getDetail: 'View details',
    confirmDeleteMoment: 'Confirm deletion',
    delete: 'Delete',
    deleteSuccess: 'Delete successfully',
    markUp: 'Annotate',
    goToProject: 'Jump to project',
    create: '{user} create',
    unSupport: 'Current format does not support upload',
    momentDetail: 'Meta Details',
    enterDescription: 'Enter description...',
    pleaseSelectedProject: 'Select publish item...',
    pleaseEnterDescription: 'Please enter a description',
    pleaseSelectFloor: 'Select project/area/floor...',
    pleaseSelectType: 'Select job type...'
  },
  appTitle: {
    // eagleMoment: 'Eagle Eye Meta,
    // jarvisEagleApp: 'Eagle Eye Introduction,
    // realTwinApp: 'Ultra Real Twin,
    // processApp: 'Process Recording,
    // remoteMonitoringApp: 'Remote Monitoring,
    // assetsManager: 'Asset Management
  },
  inland: {
    tips: 'Reminders',
    tipContent: 'Detected that you are using an international site, do you want to use a domestic site',
    sure: 'Jump',
    cancel: 'No more reminders'
  }
}
