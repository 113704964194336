<template>
  <div>
    <ArcoAvatar v-show="!src" :size="size" shape="circle">
      <div>{{ name.charAt(0) }}</div>
      <template #trigger-icon v-if="isEdit">
        <IconEdit :style="{ color: '#168CFF' }" />
      </template>
    </ArcoAvatar>
    <ArcoAvatar v-show="src" :size="size" shape="circle">
      <img :alt="name" :src="src" />
      <template #trigger-icon v-if="isEdit">
        <IconEdit :style="{ color: '#168CFF' }" />
      </template>
    </ArcoAvatar>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Avatar as ArcoAvatar } from '@arco-design/web-vue'
import { IconEdit } from '@arco-design/web-vue/es/icon'
export default defineComponent({
  name: 'Avatar',
  components: {
    ArcoAvatar,
    IconEdit
  },
  props: {
    size: {
      type: Number,
      default () {
        return undefined
      }
    },
    name: {
      type: String,
      default () {
        return ''
      }
    },
    src: {
      type: String,
      default () {
        return undefined
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped lang="less">

</style>
