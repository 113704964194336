export default {
  header: {
    settings: '设置',
    notify: '通知',
    language: '语言',
    workbench: '工作台',
    projectList: '项目列表',
    projectCollection: '项目收藏',
    enterprise: '企业',
    logout: '退出登录',
    message: '消息',
    projectSetting: '项目设置',
    userCenter: '个人信息'
  },
  enterprise: {
    enterpriseSettings: '企业设置',
    menu: {
      basicInformation: '基础信息',
      enterpriseInformation: '企业信息',
      user: '人员',
      organizationAndMembers: '组织与成员',
      enterpriseRoleManagement: '企业角色管理',
      projectRoleManagement: '项目角色管理',
      application: '应用',
      projectApplicationManagement: '项目应用管理',
      project: '项目',
      projectManagement: '项目管理',
      projectCapacity: '项目容量',
      personnelQuota: '人员名额',
      memberManagement: '人员管理'
    },
    memberManagement: {
      title: '人员管理',
      invitePeople: '邀请人员',
      enterName: '请输入昵称搜索...',
      project: '所在项目',
      role: '企业角色',
      state: '状态',
      all: '全部',
      userId: '用户Id',
      username: '姓名',
      email: '邮箱',
      action: '操作',
      enabled: '启用',
      disabled: '禁用'
    }
  },
  project: {
    projectSettings: '项目设置',
    searchProject: '搜索项目',
    menu: {
      projectInfo: '项目信息',
      projectMembers: '项目人员',
      projectRole: '项目角色',
      projectAppsManagement: '项目应用管理'
    },
    projectList: {
      members: '{count}名成员',
      started: '已开始{days}天',
      lastUpdated: '最近更新{time}'
    },
    favAppSuccess: '收藏应用成功',
    deleteAppSuccess: '取消收藏应用成功',
    projectMembers: {
      title: '项目人员',
      invitePeople: '邀请人员',
      enterName: '请输入昵称搜索...',
      project: '所在项目',
      state: '状态',
      all: '全部',
      userId: '用户Id',
      username: '姓名',
      email: '邮箱',
      role: '项目角色',
      action: '操作',
      enabled: '启用',
      disabled: '禁用'
    }
  },
  admin: {
    invite: {
      invitePerson: '邀请人员',
      inviteByEmail: '通过邮箱邀请',
      inviteByLink: '通过链接邀请',
      invite: '邀请',
      copyLink: '复制链接',
      enterpriseRole: '企业角色',
      enterRole: '请选择角色',
      enterProject: '请选择角项目',
      project: '所在项目',
      projectRole: '项目角色',
      inviteEmail: '邀请人员邮箱',
      inviteEmailTips: "请按格式【邮箱地址】分割（多行回车分割），例如：kason123{'@'}jarvisbim.com"
    }
  },
  accountProfiles: {
    userInfo: '个人信息',
    changeOrg: '切换企业',
    name: '姓名',
    passSafe: '安全与登录',
    pass: '密码',
    edit: '编辑',
    sure: '确定',
    changeName: '修改姓名',
    enterName: '请输入姓名',
    editName: '修改名字成功',
    save: '保存',
    editPass: '修改密码',
    oldPass: '旧密码',
    newPassWithRule: '新密码（长度在8到20位之间，并且包含英文字母、数字、符号的密码）',
    newPass: '新密码',
    enterNewPassAgain: '再次输入新密码',
    enterOldPass: '请输入旧密码',
    enterNewPass: '请输入新密码',
    passRule: '请输入长度在8到20位之间，并且包含英文字母、数字、符号的密码',
    passDifferent: '两次密码输入不一致',
    // 绑定手机
    bindPhone: '绑定手机',
    phone: '手机号',
    unbind: '未绑定',
    bind: '绑定',
    phoneTips: '请输入手机号',
    bindPhoneTips: '请输入正确手机号',
    verifyCode: '验证码',
    sendVerifyCode: '发送验证码',
    bindPhoneSuccess: '绑定手机成功',
    // 绑定邮箱
    email: '邮箱',
    emailTips: '请输入邮箱',
    bindEmailTips: '请输入邮箱',
    bindEmailSuccess: '绑定邮箱成功',
    emailErrorTips: '请输入有效的电子邮件地址',
    editPassSuccess: '修改密码成功',
    bindEmail: '绑定邮箱',
    confirmBind: '确认绑定',
    emailAddress: '邮箱地址',
    emailInputTips: '输入将要绑定邮箱地址',
    //   新个人信息
    editInfoTitle: '编辑个人信息',
    editAvatar: '修改头像',
    editAvatarTip: '为保证显示效果，推荐使用1:1比例，格式为jpg、png的图片，图片体积不超过4MB',
    avatarError: '当前格式不支持上传',
    changeSuccess: '修改成功',
    editNickname: '修改名称',
    editNicknameTip: '输入名称',
    sizeLimit: '图片体积超过4MB',
    // 新修改密码
    confirmEdit: '确认修改',
    oldPsw: '原密码',
    enterOldPsw: '输入原密码',
    newPsw: '新的密码',
    enterPsw: '输入密码',
    confirmPsw: '确认密码',
    pswRule: '必须为长度在8到20位之间，并且包含英文字母、数字、符号的密码',
    setted: '已设置',
    cancel: '取消'
  },
  login: {
    login: '登录',
    userInputTips: '输入手机号或邮箱',
    pswInputTips: '请输入密码',
    loginTitle: '登录鹰眼',
    jllTitle: '欢迎来到筑视',
    forgetPsw: '忘记密码',
    userNullError: '请输入手机号或邮箱',
    pswNullError: '请输入密码',
    smsLogin: '短信登录',
    accountLogin: '账号登录',
    getCode: '获取验证码',
    inputCode: '请输入验证码',
    enterAccount: '请输入邮箱或账号名',
    mobilePhoneNumber: '请输入手机号',
    sendSuccess: '发送验证码成功',
    phoneErrorRule: '手机号格式不正确',
    codeErrorRule: '验证码应为6位数字'
  },
  forgetPassword: {
    formTitle: '忘记密码',
    phone: '手机号',
    email: '邮箱',
    verificationCode: '验证码',
    getCode: '获取验证码',
    enterPassword: '输入新密码',
    confirmPassword: '确认密码',
    resetPassword: '重置密码',
    phoneErrorTips: '请输入11位手机号码',
    getVerifyCodeSuccessTips: '获取验证码成功',
    confirmPasswordErrorTips: '密码不一致',
    resetPasswordSuccessTips: '重置密码成功',
    backToLogin: '返回登录页',
    emailTips: '输入您的邮箱',
    emailNullError: '请输入您的邮箱',
    emailRuleError: '请输入正确的邮箱格式',
    phoneTips: '输入您的手机号',
    verificationTips: '输入验证码',
    passwordTips: '输入密码',
    passwordRuleError: '长度在8到20位之间，并且包含英文字母、数字、符号的密码',
    second: '秒',
    phoneTab: '通过手机验证',
    emailTab: '通过邮箱验证'
  },
  signIn: {
    formTitle: '注册个人信息',
    email: '邮箱',
    name: '姓名',
    enterPassword: '输入新密码',
    confirmPassword: '再次输入新密码',
    signIn: '提交',
    backToLogin: '已有账号？去登录',
    emailTips: '输入您的邮箱',
    nameTips: '输入姓名',
    passwordTips: '输入密码',
    confirmPasswordTips: '确认密码',
    emailNullError: '请输入您的邮箱',
    userNullError: '请输入姓名',
    passwordNullError: '输入新密码',
    checkPassNullError: '请再次输入新密码',
    checkPassDifferentError: '与新密码不一致',
    emailRuleError: '请输入正确的邮箱格式',
    passwordRuleError: '长度在8到20位之间，并且包含英文字母、数字、符号的密码',
    withPhone: '手机注册',
    phone: '手机号',
    phoneNullError: '请输入手机号',
    verificationCode: '验证码',
    verificationTips: '请输入验证码',
    withEmail: '邮箱注册',
    phoneRuleError: '手机号格式不正确',
    codeRoleError: '验证码应为6位数字',
    sendSuccess: '发送验证码成功',
    signInSuccess: '注册成功',
    appQR: '安卓下载二维码',
    iosQR: 'IOS下载二维码',
    downloadTip: '扫二维码下载APP登录，解锁更多功能'
  },
  invite: {
    inviteTitle: '邀请加入项目',
    searchTitle: '搜索企业成员，或输入手机号、邮箱直接邀请',
    inviteTip: '过链接邀请更多的人加入',
    copyLink: '复制链接',
    effectiveDate: '邀请链接有效期至',
    copySuccessed: '复制成功',
    selectOrg: '选择企业',
    sure: '确定',
    userTitle: '项目邀请',
    userContent: '邀请您加入',
    userConfirm: '加入项目'
  },
  apps: {
    mainPage: '首页',
    projectMember: '项目成员',
    invite: '邀请',
    allMember: '全部成员',
    addMember: '添加成员',
    memberSetting: '成员设置',
    removeMember: '移除成员'
  },
  workbench: {
    tasks: '待办任务',
    application: '我发起的',
    noFavoriteApps: '无收藏应用',
    toProjectTips: '进入项目后鼠标移动至顶部应用名称点击星号图标即可收藏应用，再次点击取消收藏，通过{projectList}进入项目',
    projectList: '项目列表',
    cc: '抄送我的',
    iInitiated: '我发起的',
    iApproved: '我审批的',
    haveDone: '我已办的',
    allTasks: '全部待办',
    expired: '已过期',
    expiring: '即将到期',
    nudge: '催办',
    processingWorkflow: '及时处理任务，能够极大的提高您的工作效率',
    favoriteApps: '收藏的应用',
    unread: '待审核',
    underReview: '未读',
    workCard: '工作卡片',
    iSubmitted: '我提交的',
    table: {
      title: '标题',
      flowStatus: '流程状态',
      updateTime: '更新时间',
      initiator: '发起人'
    }
  },
  moment: {
    publishMoment: '发布至鹰眼动态',
    publish: '发布',
    cancel: '取消',
    selectedProject: '选择发布项目',
    description: '描述',
    enter: '输入',
    select: '选择',
    floor: '项目/区域/楼层',
    type: '工作类型',
    principals: '负责人',
    other: '其他',
    pleaseText: '请',
    publishSuccess: '发布成功',
    publishComment: '发布评论...',
    send: '发送',
    comment: '评论',
    replyComment: '回复评论',
    newReply: '新增回复...',
    reply: '回复',
    photoDetail: '图片详情',
    typeCount: '类型统计',
    userCount: '用户统计',
    tag: '标签',
    back: '返回',
    allProject: '全部项目',
    mine: '我的',
    commented: '评论了',
    replied: '回复了',
    liked: '点赞了',
    moment: '动态',
    detail: '详情',
    loadMore: '加载更多',
    message: '消息',
    all: '全部',
    like: '点赞',
    eagleEye: '鹰眼动态',
    ernterContent: '请输入内容',
    goTop: '置顶',
    getDetail: '查看详情',
    confirmDeleteMoment: '是否确认删除动态',
    delete: '删除',
    deleteSuccess: '删除成功',
    markUp: '批注',
    goToProject: '跳转项目',
    create: '{user} 创建',
    unSupport: '当前格式不支持上传',
    momentDetail: '动态详情',
    enterDescription: '输入描述...',
    pleaseSelectedProject: '选择发布项目...',
    pleaseEnterDescription: '请输入描述',
    pleaseSelectFloor: '选择项目/区域/楼层...',
    pleaseSelectType: '选择工作类型...'
  },
  appTitle: {
    // eagleMoment: '鹰眼动态',
    // jarvisEagleApp: '鹰眼介绍',
    // realTwinApp: '超真实孪生',
    // processApp: '过程记录',
    // remoteMonitoringApp: '远程监控',
    // assetsManager: '资产管理'
  },
  inland: {
    tips: '提示',
    tipContent: '检测到你使用国际地址,是否使用国内地址',
    sure: '跳转',
    cancel: '不再提醒'
  }
}
