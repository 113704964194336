<template>
  <ArcoModal
    :title="$t('accountProfiles.editPass')"
    title-align="start"
    v-model:visible="visible"
    :on-before-ok="submit"
    @before-close="close"
    :ok-text="$t('accountProfiles.confirmEdit')"
    :cancel-text="$t('accountProfiles.cancel')">
    <ArcoForm
      :model="form"
      ref="formRef"
      label-align="left"
      :rules="rules">
      <ArcoFormItem
        hide-asterisk
        field="oldPass"
        :label="$t('accountProfiles.oldPsw')">
        <ArcoInputPassword
          v-model="form.oldPass"
          :placeholder="$t('accountProfiles.enterOldPsw')"
          allow-clear/>
      </ArcoFormItem>
      <ArcoFormItem
        hide-asterisk
        field="newPass"
        :label="$t('accountProfiles.newPsw')"
        validate-trigger="input">
        <ArcoInputPassword
          v-model="form.newPass"
          :placeholder="$t('accountProfiles.enterPsw')"
          allow-clear/>
      </ArcoFormItem>
      <ArcoFormItem
        hide-asterisk
        field="confirmPass"
        :label="$t('accountProfiles.confirmPsw')">
        <ArcoInputPassword
          v-model="form.confirmPass"
          :placeholder="$t('accountProfiles.confirmPsw')"
          allow-clear/>
      </ArcoFormItem>
      <ArcoAlert>{{ $t('accountProfiles.pswRule') }}</ArcoAlert>
    </ArcoForm>
  </ArcoModal>
</template>
<script setup>
import { reactive, ref, defineExpose } from 'vue'
import {
  Modal as ArcoModal,
  Form as ArcoForm,
  FormItem as ArcoFormItem,
  InputPassword as ArcoInputPassword,
  Alert as ArcoAlert,
  Message
} from '@arco-design/web-vue'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'

const formRef = ref(null)
const { t } = useI18n()

const visible = ref(false)
const form = reactive({
  oldPass: '',
  newPass: '',
  confirmPass: ''
})

const oldPassValidator = async (value, callback) => {
  if (!value || value === '') {
    callback(t('accountProfiles.enterOldPass'))
  } else {
    return Promise.resolve()
  }
}

const newPassValidator = async (value, callback) => {
  const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,20}$/
  if (!value || value === '') {
    callback(t('accountProfiles.enterNewPass'))
  } else if (value && !reg.test(value)) {
    callback(t('accountProfiles.passRule'))
  } else {
    return Promise.resolve()
  }
}

const confirmPassValidator = async (value, callback) => {
  if (form.newPass !== value) {
    callback(t('accountProfiles.passDifferent'))
  } else {
    return Promise.resolve()
  }
}
const rules = ref({
  oldPass: [
    { required: true, validator: oldPassValidator, trigger: 'blur' }
  ],
  newPass: [
    { required: true, validator: newPassValidator, trigger: 'input' }
  ],
  confirmPass: [
    { required: true, validator: confirmPassValidator, trigger: 'blur' }
  ]
})

const show = () => {
  visible.value = true
}

const submit = async () => {
  try {
    const res = await formRef.value?.validate()
    if (res) {
      // 校验失败，不关闭Modal
      return false
    }
    // context.emit('ok')
    await updatePassword()
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}

const updatePassword = async () => {
  try {
    const res = await api.user.updateUserPass({
      password: form.oldPass,
      newPassword: form.newPass
    })
    if (res.code === 200) {
      Message.success(t('accountProfiles.editPassSuccess'))
    } else {
      return Promise.reject(new Error(''))
    }
  } catch (error) {
    return Promise.reject(new Error(''))
  }
}

const close = () => {
  formRef.value.resetFields()
}

defineExpose({
  show
})

</script>

<style scoped lang="less">

</style>
